import { createBrowserRouter, Navigate, RouterProvider } from 'react-router';
import HomePage from './home/HomePage';
import LoginPage from './login/LoginPage';
import CheckerPage from './home/nss/CheckerPage';
import MyPage from './home/me/MyPage';

const router = createBrowserRouter([
  {
    index: true,
    element: <Navigate to="/home" replace={true} />,
  },
  {
    path: '/home',
    element: <HomePage />,
    children: [
      {
        index: true,
        element: <Navigate to="nss" />,
      },
      {
        path: 'nss',
        element: <CheckerPage />,
      },
      {
        path: 'me',
        element: <MyPage />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
