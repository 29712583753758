import {
  Device,
  DeviceState,
  ErrorState,
  LoadedState,
  useCheckerState,
  useResetCheckerState,
} from './CheckerState';
import { TwButton } from '../../common/TwButton';
import { TwCard } from '../../common/TwCard';
import React, { useState } from 'react';
import { AddDeviceDialog } from './AddDeviceDialog';
import classNames from 'classnames';
import closeIconGray from '../../assets/icons/close_gray.svg';
import closeIconWhite from '../../assets/icons/close_white.svg';
import { useShallow } from 'zustand/react/shallow';

export default function CheckerPage() {
  useResetCheckerState();

  return (
    <TwCard className="flex flex-col gap-4">
      <div className="w-full text-center font-semibold">NSS checker</div>
      <DevicesList />
      <AddDeviceButton />
    </TwCard>
  );
}

function DevicesList() {
  const [devices, removeDevice] = useCheckerState(
    useShallow((state) => [state.devices, state.removeDevice]),
  );
  if (devices.length === 0) {
    return <div>No devices added yet</div>;
  }
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      {devices.map((device) => (
        <DeviceCard
          key={device.id}
          device={device}
          onRemove={() => {
            removeDevice(device.id);
          }}
        />
      ))}
    </div>
  );
}

function AddDeviceButton() {
  const [showDialog, setShowDialog] = useState(false);
  const onClick = () => {
    setShowDialog(true);
  };

  return (
    <>
      <TwButton onClick={onClick} type="button">
        Add device
      </TwButton>
      {showDialog && (
        <AddDeviceDialog onDismissRequest={() => setShowDialog(false)} />
      )}
    </>
  );
}

const GOOD_NSS_THRESHOLD = 7;

function DeviceCard({
  device,
  onRemove,
}: {
  device: Device;
  onRemove: () => void;
}) {
  const cls = classNames(
    'rounded shadow-md p-4 flex justify-between gap-2 transition-all truncate',
    {
      'text-white':
        device.state.type === 'error' || device.state.type === 'loaded',

      'bg-gray-300': device.state.type === 'loading',

      'bg-red-700': device.state.type === 'error',

      'bg-red-500': device.state.type === 'loaded' && !device.state.isOnline,

      'bg-green-600':
        device.state.type === 'loaded' &&
        device.state.isOnline &&
        (device.state.nss ?? 0) >= GOOD_NSS_THRESHOLD,

      'bg-orange-500':
        device.state.type === 'loaded' &&
        device.state.isOnline &&
        (device.state.nss ?? 0) < GOOD_NSS_THRESHOLD,
    },
  );
  return (
    <div className={cls}>
      <div className="flex flex-col gap-2 truncate">
        <div className="font-bold">{device.id}</div>
        {device.state.type === 'loading' && <LoadingStateCardContent />}
        {device.state.type === 'error' && (
          <ErrorStateCardContent state={device.state} />
        )}
        {device.state.type === 'loaded' && (
          <LoadedStateCardContent state={device.state} />
        )}
      </div>
      <CloseButton state={device.state} onClick={onRemove} />
    </div>
  );
}

function CloseButton({
  state,
  onClick,
}: {
  state: DeviceState;
  onClick: () => void;
}) {
  return (
    <button
      type="button"
      className="shrink-0 rounded p-1 hover:bg-black/10"
      onClick={onClick}
    >
      <img
        src={state.type === 'loading' ? closeIconGray : closeIconWhite}
        alt="Remove device"
        className="size-[24px]"
      />
    </button>
  );
}

function LoadingStateCardContent() {
  return <div>Loading...</div>;
}

function ErrorStateCardContent({ state }: { state: ErrorState }) {
  return <div className="font-semibold">Error: {state.message}</div>;
}

function LoadedStateCardContent({ state }: { state: LoadedState }) {
  return (
    <>
      <div className="font-bold">{state.isOnline ? 'ONLINE' : 'OFFLINE'}</div>
      <div className="font-semibold">NSS: {state.nss ?? 'unknown'}</div>
      {state.updatedAt && <div>Updated {whenUpdated(state.updatedAt)}</div>}
    </>
  );
}

const relativeTimeFormat = new Intl.RelativeTimeFormat('en', {
  numeric: 'auto',
  style: 'long',
});

function whenUpdated(updatedAt: string | Date) {
  updatedAt = new Date(updatedAt);
  const now = new Date();
  const dtMillis = now.getTime() - updatedAt.getTime();

  if (dtMillis < 1000) {
    return 'just now';
  }

  const dtSeconds = dtMillis / 1000;
  const dtSecondsApprox = Math.round(dtSeconds);
  if (dtSecondsApprox < 60) {
    return relativeTimeFormat.format(-dtSecondsApprox, 'seconds');
  }

  const dtMinutes = dtSeconds / 60;
  const dtMinutesApprox = Math.round(dtMinutes);
  if (dtMinutesApprox < 60) {
    return relativeTimeFormat.format(-dtMinutesApprox, 'minutes');
  }

  const dtHours = dtMinutes / 60;
  const dtHoursApprox = Math.round(dtHours);
  if (dtHoursApprox < 24) {
    return relativeTimeFormat.format(-dtHoursApprox, 'hours');
  }

  const dtDays = dtHours / 24;
  const dtDaysApprox = Math.round(dtDays);
  return relativeTimeFormat.format(-dtDaysApprox, 'days');
}
