import { create } from 'zustand';
import { useEffect } from 'react';
import { provisioningApi } from '../../common/services/iot-core-api/Service';
import { delay } from '../../common/alg/Delay';
import { isAxiosError } from 'axios';

export interface DeviceState {
  type: 'loading' | 'loaded' | 'error';
}

export interface LoadingState extends DeviceState {
  type: 'loading';
}

export interface LoadedState extends DeviceState {
  type: 'loaded';
  isOnline: boolean;
  nss: number | undefined;
  updatedAt: Date | undefined;
}

export interface ErrorState extends DeviceState {
  type: 'error';
  message: string;
}

export type OneOfDeviceState = LoadingState | LoadedState | ErrorState;

export interface Device {
  id: string;
  state: OneOfDeviceState;
  abortController: AbortController;
}

export interface CheckerState {
  devices: Device[];
  addDevice: (deviceId: string) => void;
  removeDevice: (deviceId: string) => void;
  reset: () => void;

  abortController: AbortController;
}

// const mockDevices: Device[] = [
//   {
//     id: 'dev-1',
//     state: {
//       type: 'loading',
//     },
//     abortController: new AbortController(),
//   },
//   {
//     id: 'dev-2',
//     state: {
//       type: 'error',
//       message: "Can't find device",
//     },
//     abortController: new AbortController(),
//   },
//   {
//     id: 'dev-3',
//     state: {
//       type: 'loaded',
//       isOnline: false,
//       nss: 0,
//       updatedAt: new Date(),
//     },
//     abortController: new AbortController(),
//   },
//   {
//     id: 'dev-4',
//     state: {
//       type: 'loaded',
//       isOnline: true,
//       nss: 10,
//       updatedAt: new Date(),
//     },
//     abortController: new AbortController(),
//   },
// ];

export const useCheckerState = create<CheckerState>()((setState, getState) => ({
  devices: [],

  abortController: new AbortController(),

  addDevice: (deviceId: string) => {
    const hasDevice = getState().devices.some(
      (device) => device.id === deviceId,
    );
    if (hasDevice) {
      return;
    }

    const abortController = new AbortController();
    getState().abortController.signal.addEventListener(
      'abort',
      () => {
        abortController.abort();
      },
      {
        signal: abortController.signal,
      },
    );

    refreshLoop(deviceId, abortController.signal, (newDeviceState) => {
      setState((currentState) => ({
        devices: currentState.devices.map((device) => {
          if (device.id === deviceId) {
            return {
              ...device,
              state: newDeviceState,
            };
          }
          return device;
        }),
      }));
    }).catch((err) => {
      console.error(err);
    });

    setState({
      devices: [
        ...getState().devices,
        {
          id: deviceId,
          abortController: abortController,
          state: {
            type: 'loading',
          },
        },
      ],
    });
  },

  removeDevice: (deviceId: string) => {
    const device = getState().devices.find((device) => device.id === deviceId);
    if (!device) {
      return;
    }

    device.abortController.abort();

    setState({
      devices: getState().devices.filter((device) => device.id !== deviceId),
    });
  },

  reset: () => {
    getState().abortController.abort();
    setState({
      devices: [],
      abortController: new AbortController(),
    });
  },
}));

export function useResetCheckerState() {
  const reset = useCheckerState((state) => state.reset);
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);
}

const REFRESH_INTERVAL_MILLIS = 15_000;
const GET_API_TIMEOUT = 5_000;
const CMD_API_TIMEOUT = 10_000;

async function refreshLoop(
  deviceId: string,
  signal: AbortSignal,
  onTick: (newState: OneOfDeviceState) => void,
) {
  try {
    while (!signal.aborted) {
      try {
        const [{ data: device }, { data: twin }] = await Promise.all([
          provisioningApi.getDeviceAsProvisioner(deviceId, {
            signal: signal,
            timeout: GET_API_TIMEOUT,
          }),
          provisioningApi.getTwinAsProvisioner(deviceId, {
            signal: signal,
            timeout: GET_API_TIMEOUT,
          }),
        ]);

        onTick({
          type: 'loaded',
          isOnline: device.isOnline,
          nss: twin.reported?.nss?.value as number,
          updatedAt: twin.reported.nss?.updatedAt
            ? new Date(twin.reported.nss?.updatedAt)
            : undefined,
        });

        await requestDeviceToUpdateNSS(deviceId, signal);
      } catch (e: unknown) {
        onTick({
          type: 'error',
          message: isAxiosError(e)
            ? (e.response?.data?.message ?? e.message)
            : (e as { message: string })?.message,
        });
      }

      await delay(REFRESH_INTERVAL_MILLIS, signal);
    }
  } catch (e) {
    console.error(e);
  }

  console.log(`[${deviceId}] refresh loop ended`);
}

async function requestDeviceToUpdateNSS(deviceId: string, signal: AbortSignal) {
  try {
    const timeoutSec = Math.round(CMD_API_TIMEOUT / 1000);
    const maxNAttempts = 1;
    await provisioningApi.invokeCommandOnDeviceAsProvisioner(
      deviceId,
      {
        name: 'network_info',
      },
      timeoutSec,
      maxNAttempts,
      {
        signal: signal,
        timeout: CMD_API_TIMEOUT,
      },
    );
  } catch (e: unknown) {
    console.error(`[${deviceId}] request device to update nss failed:`, e);
  }
}
