import { useAuthStore } from '../common/auth/AuthStore';
import { Navigate, Outlet } from 'react-router';
import touwiLogo from '../assets/images/touwi.svg';

export default function HomePage() {
  const isAuthenticated = useAuthStore((state) => !!state.token);

  if (!isAuthenticated) {
    return <Navigate to={'/login'} replace={true} />;
  }

  return (
    <div className="flex h-svh w-screen flex-col bg-gray-200">
      <header className="flex h-16 max-h-16 w-full items-center justify-between gap-2 bg-white p-4 shadow-md">
        <TouwiLogo />
        <Title />
        <LogoutButton />
      </header>
      <main className="p-4">
        <Outlet />
      </main>
    </div>
  );
}

function Title() {
  return <div className="truncate font-semibold">Post Prod Tools</div>;
}

function TouwiLogo() {
  return <img src={touwiLogo} alt="Touwi Logo" className="h-full" />;
}

function LogoutButton(props: { className?: string }) {
  const setToken = useAuthStore((state) => state.setToken);
  return (
    <button
      type="button"
      onClick={() => setToken(undefined)}
      className={`rounded p-2 outline-indigo-600 hover:bg-indigo-600/50 hover:text-white ${props.className ?? ''}`}
    >
      Logout
    </button>
  );
}
