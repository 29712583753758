import { Modal } from '../../common/Modal';
import React, { FormEvent, useState } from 'react';
import { TwInputWithLabel } from '../../common/TwInputWithLabel';
import { TwInputLabel } from '../../common/TwInputLabel';
import { TwInput } from '../../common/TwInput';
import { TwButton } from '../../common/TwButton';
import { useCheckerState } from './CheckerState';
import { Scanner } from '@yudiel/react-qr-scanner';
import { TwCard } from '../../common/TwCard';

export function AddDeviceDialog(props: { onDismissRequest?: () => void }) {
  return (
    <Modal
      className="flex items-center justify-center"
      onDismissRequest={props.onDismissRequest}
    >
      <DialogContent onDismissRequest={props.onDismissRequest} />
    </Modal>
  );
}

function DialogContent(props: { onDismissRequest?: () => void }) {
  const addDevice = useCheckerState((state) => state.addDevice);

  const addDeviceAndDismiss = (deviceId: string) => {
    const normalised = deviceId.trim();
    if (normalised !== '') {
      addDevice(normalised);
      props.onDismissRequest?.();
    }
  };

  const [deviceId, setDeviceId] = useState('');

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    addDeviceAndDismiss(deviceId);
  };

  return (
    <TwCard
      className="m-4 max-w-lg"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <DeviceIdInput value={deviceId} setValue={setDeviceId} />
        <DeviceQrCodeScanner onScan={addDeviceAndDismiss} />
        <SubmitButton disabled={deviceId.trim().length === 0} />
      </form>
    </TwCard>
  );
}

function DeviceIdInput(props: {
  value: string;
  setValue: (value: string) => void;
}) {
  return (
    <TwInputWithLabel
      label={<TwInputLabel htmlFor="device-id">Device id</TwInputLabel>}
      input={
        <TwInput
          inputId="device-id"
          value={props.value}
          onValue={props.setValue}
          required
        />
      }
    />
  );
}

function DeviceQrCodeScanner(props: { onScan: (deviceId: string) => void }) {
  return (
    <Scanner
      components={{
        audio: false,
      }}
      onScan={(result) => {
        const value = result[0]?.rawValue;
        if (value) {
          props.onScan(value);
        }
      }}
    />
  );
}

function SubmitButton(props: { disabled: boolean }) {
  return (
    <TwButton type="submit" disabled={props.disabled}>
      Add
    </TwButton>
  );
}
