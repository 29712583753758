export async function delay(
  millis: number,
  signal: AbortSignal | undefined = undefined,
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (signal?.aborted) {
      reject(signal.reason);
    }

    const timer = setTimeout(resolve, millis);
    if (signal) {
      signal?.addEventListener('abort', () => {
        clearTimeout(timer);
        reject(signal.reason);
      });
    }
  });
}
