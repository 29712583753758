import { useAuthStore } from '../../common/auth/AuthStore';
import { useMemo } from 'react';
import { TwCard } from '../../common/TwCard';

export default function MyPage() {
  const token = useAuthStore((state) => state.token);
  const payload = useMemo(() => {
    return parseToken(token);
  }, [token]);

  return (
    <div className="flex w-full items-center justify-center">
      {payload && (
        <TwCard className="grid w-full grid-cols-[auto_1fr] gap-4">
          <KeyValue label={'Token id'} value={payload.tokenId} />
          <KeyValue label={'Hub'} value={payload.hub} />
          <KeyValue
            label={'Expires at'}
            value={payload.expiresAt.toLocaleString()}
          />
          <KeyValue label={'Lot id'} value={payload.lotId.toString()} />
        </TwCard>
      )}
    </div>
  );
}

function KeyValue(props: { label: string; value: string }) {
  return (
    <>
      <div className="font-bold">{`${props.label}:`}</div>
      <div>{props.value}</div>
    </>
  );
}

function parseToken(token: string | undefined) {
  try {
    if (!token) {
      return undefined;
    }

    const payload = JSON.parse(atob(token.split('.')[1]));
    return {
      tokenId: payload.jti as string,
      hub: payload.hub as string,
      expiresAt: new Date(payload.exp * 1000),
      lotId: payload.sub as number,
    };
  } catch (e: unknown) {
    console.warn(e);
    return undefined;
  }
}
