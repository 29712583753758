/* tslint:disable */
/* eslint-disable */
/**
 * SA iot-core service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: c86184c5ac0c3177e15968f30c521d4e89435480
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from './base';

/**
 *
 * @export
 * @interface ActionResultDto
 */
export interface ActionResultDto {
  /**
   *
   * @type {boolean}
   * @memberof ActionResultDto
   */
  isSuccess: boolean;
}
/**
 *
 * @export
 * @interface AttributeDeltaDto
 */
export interface AttributeDeltaDto {
  /**
   *
   * @type {string}
   * @memberof AttributeDeltaDto
   */
  key: string;
  /**
   *
   * @type {TwinAttributeValueDto}
   * @memberof AttributeDeltaDto
   */
  desired?: TwinAttributeValueDto;
  /**
   *
   * @type {TwinAttributeValueDto}
   * @memberof AttributeDeltaDto
   */
  reported?: TwinAttributeValueDto;
}
/**
 *
 * @export
 * @interface Base64StringCommandPayloadDto
 */
export interface Base64StringCommandPayloadDto {
  /**
   *
   * @type {string}
   * @memberof Base64StringCommandPayloadDto
   */
  type: Base64StringCommandPayloadDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Base64StringCommandPayloadDto
   */
  value: string;
}

export const Base64StringCommandPayloadDtoTypeEnum = {
  Base64StringCommandPayloadDto: 'Base64StringCommandPayloadDto',
} as const;

export type Base64StringCommandPayloadDtoTypeEnum =
  (typeof Base64StringCommandPayloadDtoTypeEnum)[keyof typeof Base64StringCommandPayloadDtoTypeEnum];

/**
 *
 * @export
 * @interface CommandCreateInputDto
 */
export interface CommandCreateInputDto {
  /**
   * The business logical name of the command. IoT Core service itself does not rely on this name in any way and just retransmits the name of the command to a device as is, while business-level client applications must define a contract of the command names between the backend and the device.
   * @type {string}
   * @memberof CommandCreateInputDto
   */
  name: string;
  /**
   *
   * @type {CommandCreateInputDtoPayload}
   * @memberof CommandCreateInputDto
   */
  payload?: CommandCreateInputDtoPayload;
}
/**
 * @type CommandCreateInputDtoPayload
 * The business logical payload of the command request. IoT Core service may apply payload transformation before retransmission of the payload of the command to a device, depending on the payload type. Business-level client applications must define a contract of the command names between the backend and the device.  If the payload type is a StringCommandPayload, a value of a string will be retransmitted to a device without any transformations.  If the payload type is a Base64StringCommandPayload, a source value of a base64 string will be decoded and transmitted to a device in the form of the source byte array.  If the payload type is a JsonObjectCommandPayload, a value will be transmitted to a device in the form of the JSON string.
 * @export
 */
export type CommandCreateInputDtoPayload =
  | Base64StringCommandPayloadDto
  | JsonObjectCommandPayloadDto
  | StringCommandPayloadDto;

/**
 *
 * @export
 * @interface CommandResultDto
 */
export interface CommandResultDto {
  /**
   *
   * @type {CommandResultDtoPayload}
   * @memberof CommandResultDto
   */
  payload?: CommandResultDtoPayload;
  /**
   *
   * @type {string}
   * @memberof CommandResultDto
   */
  requestSentAt: string;
  /**
   *
   * @type {string}
   * @memberof CommandResultDto
   */
  responseReceivedAt: string;
}
/**
 * @type CommandResultDtoPayload
 * The business logical payload of the command response.
 * @export
 */
export type CommandResultDtoPayload =
  | Base64StringCommandPayloadDto
  | JsonObjectCommandPayloadDto
  | StringCommandPayloadDto;

/**
 *
 * @export
 * @interface ConfidentialDeviceDto
 */
export interface ConfidentialDeviceDto {
  /**
   *
   * @type {number}
   * @memberof ConfidentialDeviceDto
   */
  provisioningLotId: number;
  /**
   * MQTT Broker URL
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  mqttBrokerUrl: string;
  /**
   * JWT Token to connect to the MQTT Broker
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  cloudAccessToken: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  mqttClientId: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  mqttUsername: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  mqttAuthType: ConfidentialDeviceDtoMqttAuthTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  status: ConfidentialDeviceDtoStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ConfidentialDeviceDto
   */
  isOnline: boolean;
  /**
   *
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  onlineUpdatedAt: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ConfidentialDeviceDto
   */
  tags: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ConfidentialDeviceDto
   */
  hubId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ConfidentialDeviceDto
   */
  messagesFormats: Array<ConfidentialDeviceDtoMessagesFormatsEnum>;
}

export const ConfidentialDeviceDtoMqttAuthTypeEnum = {
  Jwt: 'JWT',
  UsernamePassword: 'USERNAME_PASSWORD',
} as const;

export type ConfidentialDeviceDtoMqttAuthTypeEnum =
  (typeof ConfidentialDeviceDtoMqttAuthTypeEnum)[keyof typeof ConfidentialDeviceDtoMqttAuthTypeEnum];
export const ConfidentialDeviceDtoStatusEnum = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
} as const;

export type ConfidentialDeviceDtoStatusEnum =
  (typeof ConfidentialDeviceDtoStatusEnum)[keyof typeof ConfidentialDeviceDtoStatusEnum];
export const ConfidentialDeviceDtoMessagesFormatsEnum = {
  Json: 'JSON',
  Binary: 'BINARY',
} as const;

export type ConfidentialDeviceDtoMessagesFormatsEnum =
  (typeof ConfidentialDeviceDtoMessagesFormatsEnum)[keyof typeof ConfidentialDeviceDtoMessagesFormatsEnum];

/**
 *
 * @export
 * @interface ConnectionLogEntryDto
 */
export interface ConnectionLogEntryDto {
  /**
   *
   * @type {number}
   * @memberof ConnectionLogEntryDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ConnectionLogEntryDto
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof ConnectionLogEntryDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof ConnectionLogEntryDto
   */
  status: ConnectionLogEntryDtoStatusEnum;
}

export const ConnectionLogEntryDtoStatusEnum = {
  Connected: 'CONNECTED',
  Disconnected: 'DISCONNECTED',
} as const;

export type ConnectionLogEntryDtoStatusEnum =
  (typeof ConnectionLogEntryDtoStatusEnum)[keyof typeof ConnectionLogEntryDtoStatusEnum];

/**
 *
 * @export
 * @interface ConnectionLogsPageDto
 */
export interface ConnectionLogsPageDto {
  /**
   *
   * @type {Array<ConnectionLogEntryDto>}
   * @memberof ConnectionLogsPageDto
   */
  items: Array<ConnectionLogEntryDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof ConnectionLogsPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface DateFilterDto
 */
export interface DateFilterDto {
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  gt?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  gte?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  lt?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  lte?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  eq?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  ne?: string;
}
/**
 *
 * @export
 * @interface DeviceCreateBatchInputDto
 */
export interface DeviceCreateBatchInputDto {
  /**
   *
   * @type {Array<DeviceCreateInputDto>}
   * @memberof DeviceCreateBatchInputDto
   */
  devices: Array<DeviceCreateInputDto>;
}
/**
 *
 * @export
 * @interface DeviceCreateInputDto
 */
export interface DeviceCreateInputDto {
  /**
   * deviceId comes from manufacture
   * @type {string}
   * @memberof DeviceCreateInputDto
   */
  deviceId: string;
  /**
   * Required if devices mqtt auth type is USERNAME_PASSWORD.
   * @type {string}
   * @memberof DeviceCreateInputDto
   */
  mqttPassword?: string;
  /**
   * Can be used by customer services to authenticate device within cloud API using device cloud API password.
   * @type {string}
   * @memberof DeviceCreateInputDto
   */
  cloudApiPassword?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof DeviceCreateInputDto
   */
  tags?: Array<string>;
}
/**
 *
 * @export
 * @interface DeviceDto
 */
export interface DeviceDto {
  /**
   *
   * @type {number}
   * @memberof DeviceDto
   */
  provisioningLotId: number;
  /**
   *
   * @type {string}
   * @memberof DeviceDto
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDto
   */
  status: DeviceDtoStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof DeviceDto
   */
  isOnline: boolean;
  /**
   *
   * @type {string}
   * @memberof DeviceDto
   */
  onlineUpdatedAt: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DeviceDto
   */
  tags: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DeviceDto
   */
  hubId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DeviceDto
   */
  messagesFormats: Array<DeviceDtoMessagesFormatsEnum>;
}

export const DeviceDtoStatusEnum = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
} as const;

export type DeviceDtoStatusEnum =
  (typeof DeviceDtoStatusEnum)[keyof typeof DeviceDtoStatusEnum];
export const DeviceDtoMessagesFormatsEnum = {
  Json: 'JSON',
  Binary: 'BINARY',
} as const;

export type DeviceDtoMessagesFormatsEnum =
  (typeof DeviceDtoMessagesFormatsEnum)[keyof typeof DeviceDtoMessagesFormatsEnum];

/**
 *
 * @export
 * @interface DeviceIdentityDto
 */
export interface DeviceIdentityDto {
  /**
   *
   * @type {string}
   * @memberof DeviceIdentityDto
   */
  hubId: string;
  /**
   *
   * @type {string}
   * @memberof DeviceIdentityDto
   */
  deviceId: string;
}
/**
 *
 * @export
 * @interface DevicePatchInputDto
 */
export interface DevicePatchInputDto {
  /**
   *
   * @type {string}
   * @memberof DevicePatchInputDto
   */
  status?: DevicePatchInputDtoStatusEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof DevicePatchInputDto
   */
  tags?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DevicePatchInputDto
   */
  messagesFormats?: Array<DevicePatchInputDtoMessagesFormatsEnum>;
}

export const DevicePatchInputDtoStatusEnum = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
} as const;

export type DevicePatchInputDtoStatusEnum =
  (typeof DevicePatchInputDtoStatusEnum)[keyof typeof DevicePatchInputDtoStatusEnum];
export const DevicePatchInputDtoMessagesFormatsEnum = {
  Json: 'JSON',
  Binary: 'BINARY',
} as const;

export type DevicePatchInputDtoMessagesFormatsEnum =
  (typeof DevicePatchInputDtoMessagesFormatsEnum)[keyof typeof DevicePatchInputDtoMessagesFormatsEnum];

/**
 *
 * @export
 * @interface DevicesPageDto
 */
export interface DevicesPageDto {
  /**
   *
   * @type {Array<DeviceDto>}
   * @memberof DevicesPageDto
   */
  items: Array<DeviceDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof DevicesPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface DevicesPageQueryPostInputDto
 */
export interface DevicesPageQueryPostInputDto {
  /**
   *
   * @type {number}
   * @memberof DevicesPageQueryPostInputDto
   */
  skip?: number;
  /**
   *
   * @type {number}
   * @memberof DevicesPageQueryPostInputDto
   */
  take?: number;
  /**
   *
   * @type {boolean}
   * @memberof DevicesPageQueryPostInputDto
   */
  includeTotalCount?: boolean;
  /**
   *
   * @type {Array<DevicesSortCriterionDto>}
   * @memberof DevicesPageQueryPostInputDto
   */
  orderBy?: Array<DevicesSortCriterionDto>;
  /**
   *
   * @type {string}
   * @memberof DevicesPageQueryPostInputDto
   */
  status?: DevicesPageQueryPostInputDtoStatusEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof DevicesPageQueryPostInputDto
   */
  tags?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DevicesPageQueryPostInputDto
   */
  devicesIds?: Array<string>;
}

export const DevicesPageQueryPostInputDtoStatusEnum = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
} as const;

export type DevicesPageQueryPostInputDtoStatusEnum =
  (typeof DevicesPageQueryPostInputDtoStatusEnum)[keyof typeof DevicesPageQueryPostInputDtoStatusEnum];

/**
 *
 * @export
 * @interface DevicesSortCriterionDto
 */
export interface DevicesSortCriterionDto {
  /**
   *
   * @type {string}
   * @memberof DevicesSortCriterionDto
   */
  key: DevicesSortCriterionDtoKeyEnum;
  /**
   *
   * @type {string}
   * @memberof DevicesSortCriterionDto
   */
  order: DevicesSortCriterionDtoOrderEnum;
}

export const DevicesSortCriterionDtoKeyEnum = {
  CreatedAt: 'createdAt',
  Status: 'status',
} as const;

export type DevicesSortCriterionDtoKeyEnum =
  (typeof DevicesSortCriterionDtoKeyEnum)[keyof typeof DevicesSortCriterionDtoKeyEnum];
export const DevicesSortCriterionDtoOrderEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type DevicesSortCriterionDtoOrderEnum =
  (typeof DevicesSortCriterionDtoOrderEnum)[keyof typeof DevicesSortCriterionDtoOrderEnum];

/**
 *
 * @export
 * @interface GetHealth200Response
 */
export interface GetHealth200Response {
  /**
   *
   * @type {string}
   * @memberof GetHealth200Response
   */
  status?: string;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth200Response
   */
  info?: { [key: string]: GetHealth200ResponseInfoValue } | null;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth200Response
   */
  error?: { [key: string]: GetHealth200ResponseInfoValue } | null;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth200Response
   */
  details?: { [key: string]: GetHealth200ResponseInfoValue };
}
/**
 *
 * @export
 * @interface GetHealth200ResponseInfoValue
 */
export interface GetHealth200ResponseInfoValue {
  [key: string]: string | any;

  /**
   *
   * @type {string}
   * @memberof GetHealth200ResponseInfoValue
   */
  status?: string;
}
/**
 *
 * @export
 * @interface GetHealth503Response
 */
export interface GetHealth503Response {
  /**
   *
   * @type {string}
   * @memberof GetHealth503Response
   */
  status?: string;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth503Response
   */
  info?: { [key: string]: GetHealth200ResponseInfoValue } | null;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth503Response
   */
  error?: { [key: string]: GetHealth200ResponseInfoValue } | null;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth503Response
   */
  details?: { [key: string]: GetHealth200ResponseInfoValue };
}
/**
 *
 * @export
 * @interface HubApiTokenCreateInputDto
 */
export interface HubApiTokenCreateInputDto {
  /**
   * Api token JWT <code>sub</code> claim will be composed as <code>{hubId}_{subjectSuffix}</code>.<br>Token <code>subject</code> must be used as MQTT username and <code>token</code> itself must be used as MQTT password when conneting to MQTT.
   * @type {string}
   * @memberof HubApiTokenCreateInputDto
   */
  subjectSuffix: string;
  /**
   *
   * @type {string}
   * @memberof HubApiTokenCreateInputDto
   */
  expiresAt?: string;
  /**
   *
   * @type {MqttUserTopicsDto}
   * @memberof HubApiTokenCreateInputDto
   */
  mqttUserTopics?: MqttUserTopicsDto;
}
/**
 *
 * @export
 * @interface HubApiTokenDto
 */
export interface HubApiTokenDto {
  /**
   * JWT token <code>sub</code> claim value.<br><code>subject</code> must be used as MQTT username and <code>token</code> must be used as MQTT password when conneting to MQTT.
   * @type {string}
   * @memberof HubApiTokenDto
   */
  subject: string;
  /**
   * <code>subject</code> must be used as MQTT username and <code>token</code> must be used as MQTT password when connecting to MQTT.
   * @type {string}
   * @memberof HubApiTokenDto
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof HubApiTokenDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof HubApiTokenDto
   */
  hubId: string;
  /**
   *
   * @type {string}
   * @memberof HubApiTokenDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof HubApiTokenDto
   */
  expiresAt?: string;
  /**
   *
   * @type {MqttUserTopicsDto}
   * @memberof HubApiTokenDto
   */
  mqttUserTopics: MqttUserTopicsDto;
}
/**
 *
 * @export
 * @interface HubCreateInputDto
 */
export interface HubCreateInputDto {
  /**
   *
   * @type {string}
   * @memberof HubCreateInputDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof HubCreateInputDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof HubCreateInputDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface HubDto
 */
export interface HubDto {
  /**
   *
   * @type {string}
   * @memberof HubDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof HubDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof HubDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof HubDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface HubUpdateInputDto
 */
export interface HubUpdateInputDto {
  /**
   *
   * @type {string}
   * @memberof HubUpdateInputDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof HubUpdateInputDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface HubsPageDto
 */
export interface HubsPageDto {
  /**
   *
   * @type {Array<HubDto>}
   * @memberof HubsPageDto
   */
  items: Array<HubDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof HubsPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface JsonObjectCommandPayloadDto
 */
export interface JsonObjectCommandPayloadDto {
  /**
   *
   * @type {string}
   * @memberof JsonObjectCommandPayloadDto
   */
  type: JsonObjectCommandPayloadDtoTypeEnum;
  /**
   *
   * @type {object}
   * @memberof JsonObjectCommandPayloadDto
   */
  value: object;
}

export const JsonObjectCommandPayloadDtoTypeEnum = {
  JsonObjectCommandPayloadDto: 'JsonObjectCommandPayloadDto',
} as const;

export type JsonObjectCommandPayloadDtoTypeEnum =
  (typeof JsonObjectCommandPayloadDtoTypeEnum)[keyof typeof JsonObjectCommandPayloadDtoTypeEnum];

/**
 *
 * @export
 * @interface LogBulkCreateInputDto
 */
export interface LogBulkCreateInputDto {
  /**
   *
   * @type {Array<LogEntryCreateInputDto>}
   * @memberof LogBulkCreateInputDto
   */
  logs: Array<LogEntryCreateInputDto>;
}
/**
 *
 * @export
 * @interface LogEntriesPageDto
 */
export interface LogEntriesPageDto {
  /**
   *
   * @type {Array<LogEntryDto>}
   * @memberof LogEntriesPageDto
   */
  items: Array<LogEntryDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof LogEntriesPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface LogEntryCreateInputDto
 */
export interface LogEntryCreateInputDto {
  /**
   *
   * @type {string}
   * @memberof LogEntryCreateInputDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof LogEntryCreateInputDto
   */
  trackingId?: string;
  /**
   *
   * @type {string}
   * @memberof LogEntryCreateInputDto
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof LogEntryCreateInputDto
   */
  level: LogEntryCreateInputDtoLevelEnum;
  /**
   *
   * @type {string}
   * @memberof LogEntryCreateInputDto
   */
  message: string;
}

export const LogEntryCreateInputDtoLevelEnum = {
  Info: 'INFO',
  Error: 'ERROR',
} as const;

export type LogEntryCreateInputDtoLevelEnum =
  (typeof LogEntryCreateInputDtoLevelEnum)[keyof typeof LogEntryCreateInputDtoLevelEnum];

/**
 *
 * @export
 * @interface LogEntryDto
 */
export interface LogEntryDto {
  /**
   *
   * @type {number}
   * @memberof LogEntryDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof LogEntryDto
   */
  trackingId?: string;
  /**
   *
   * @type {string}
   * @memberof LogEntryDto
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof LogEntryDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof LogEntryDto
   */
  receivedAt: string;
  /**
   *
   * @type {number}
   * @memberof LogEntryDto
   */
  provisioningLotId: number;
  /**
   *
   * @type {string}
   * @memberof LogEntryDto
   */
  level: LogEntryDtoLevelEnum;
  /**
   *
   * @type {string}
   * @memberof LogEntryDto
   */
  message: string;
}

export const LogEntryDtoLevelEnum = {
  Info: 'INFO',
  Error: 'ERROR',
} as const;

export type LogEntryDtoLevelEnum =
  (typeof LogEntryDtoLevelEnum)[keyof typeof LogEntryDtoLevelEnum];

/**
 *
 * @export
 * @interface MqttDeviceCredentialsDto
 */
export interface MqttDeviceCredentialsDto {
  /**
   *
   * @type {string}
   * @memberof MqttDeviceCredentialsDto
   */
  brokerUrl: string;
  /**
   *
   * @type {string}
   * @memberof MqttDeviceCredentialsDto
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof MqttDeviceCredentialsDto
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof MqttDeviceCredentialsDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface MqttUserTopicsDto
 */
export interface MqttUserTopicsDto {
  /**
   *
   * @type {Array<string>}
   * @memberof MqttUserTopicsDto
   */
  pub?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof MqttUserTopicsDto
   */
  sub?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof MqttUserTopicsDto
   */
  all?: Array<string>;
}
/**
 *
 * @export
 * @interface PageMetaDto
 */
export interface PageMetaDto {
  /**
   *
   * @type {number}
   * @memberof PageMetaDto
   */
  skip: number;
  /**
   *
   * @type {number}
   * @memberof PageMetaDto
   */
  take: number;
  /**
   * Presents if `includeTotalCount` query parameter is true
   * @type {number}
   * @memberof PageMetaDto
   */
  totalCount?: number;
}
/**
 *
 * @export
 * @interface ProvisioningLotCreateInputDto
 */
export interface ProvisioningLotCreateInputDto {
  /**
   *
   * @type {number}
   * @memberof ProvisioningLotCreateInputDto
   */
  provisionQuota: number;
  /**
   * Date when device tokens will expire
   * @type {string}
   * @memberof ProvisioningLotCreateInputDto
   */
  devicesTokenExpirationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotCreateInputDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotCreateInputDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotCreateInputDto
   */
  availableNotBefore?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotCreateInputDto
   */
  availableNotAfter?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProvisioningLotCreateInputDto
   */
  devicesMessagesFormatInitializer: Array<ProvisioningLotCreateInputDtoDevicesMessagesFormatInitializerEnum>;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotCreateInputDto
   */
  devicesMqttAuthType: ProvisioningLotCreateInputDtoDevicesMqttAuthTypeEnum;
}

export const ProvisioningLotCreateInputDtoDevicesMessagesFormatInitializerEnum =
  {
    Json: 'JSON',
    Binary: 'BINARY',
  } as const;

export type ProvisioningLotCreateInputDtoDevicesMessagesFormatInitializerEnum =
  (typeof ProvisioningLotCreateInputDtoDevicesMessagesFormatInitializerEnum)[keyof typeof ProvisioningLotCreateInputDtoDevicesMessagesFormatInitializerEnum];
export const ProvisioningLotCreateInputDtoDevicesMqttAuthTypeEnum = {
  Jwt: 'JWT',
  UsernamePassword: 'USERNAME_PASSWORD',
} as const;

export type ProvisioningLotCreateInputDtoDevicesMqttAuthTypeEnum =
  (typeof ProvisioningLotCreateInputDtoDevicesMqttAuthTypeEnum)[keyof typeof ProvisioningLotCreateInputDtoDevicesMqttAuthTypeEnum];

/**
 *
 * @export
 * @interface ProvisioningLotDto
 */
export interface ProvisioningLotDto {
  /**
   *
   * @type {number}
   * @memberof ProvisioningLotDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ProvisioningLotDto
   */
  nDevicesProvisioned: number;
  /**
   *
   * @type {number}
   * @memberof ProvisioningLotDto
   */
  provisionQuota: number;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotDto
   */
  hubId: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotDto
   */
  provisioningToken: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotDto
   */
  availableNotBefore?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotDto
   */
  availableNotAfter?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotDto
   */
  devicesTokenExpirationDate?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProvisioningLotDto
   */
  devicesMessagesFormatInitializer: Array<ProvisioningLotDtoDevicesMessagesFormatInitializerEnum>;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotDto
   */
  devicesMqttAuthType: ProvisioningLotDtoDevicesMqttAuthTypeEnum;
}

export const ProvisioningLotDtoDevicesMessagesFormatInitializerEnum = {
  Json: 'JSON',
  Binary: 'BINARY',
} as const;

export type ProvisioningLotDtoDevicesMessagesFormatInitializerEnum =
  (typeof ProvisioningLotDtoDevicesMessagesFormatInitializerEnum)[keyof typeof ProvisioningLotDtoDevicesMessagesFormatInitializerEnum];
export const ProvisioningLotDtoDevicesMqttAuthTypeEnum = {
  Jwt: 'JWT',
  UsernamePassword: 'USERNAME_PASSWORD',
} as const;

export type ProvisioningLotDtoDevicesMqttAuthTypeEnum =
  (typeof ProvisioningLotDtoDevicesMqttAuthTypeEnum)[keyof typeof ProvisioningLotDtoDevicesMqttAuthTypeEnum];

/**
 *
 * @export
 * @interface ProvisioningLotPatchInputDto
 */
export interface ProvisioningLotPatchInputDto {
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotPatchInputDto
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ProvisioningLotPatchInputDto
   */
  provisionQuota?: number;
  /**
   * Date when device tokens will expire
   * @type {string}
   * @memberof ProvisioningLotPatchInputDto
   */
  devicesTokenExpirationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotPatchInputDto
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProvisioningLotPatchInputDto
   */
  devicesMessagesFormatInitializer?: Array<ProvisioningLotPatchInputDtoDevicesMessagesFormatInitializerEnum>;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotPatchInputDto
   */
  devicesMqttAuthType?: ProvisioningLotPatchInputDtoDevicesMqttAuthTypeEnum;
}

export const ProvisioningLotPatchInputDtoDevicesMessagesFormatInitializerEnum =
  {
    Json: 'JSON',
    Binary: 'BINARY',
  } as const;

export type ProvisioningLotPatchInputDtoDevicesMessagesFormatInitializerEnum =
  (typeof ProvisioningLotPatchInputDtoDevicesMessagesFormatInitializerEnum)[keyof typeof ProvisioningLotPatchInputDtoDevicesMessagesFormatInitializerEnum];
export const ProvisioningLotPatchInputDtoDevicesMqttAuthTypeEnum = {
  Jwt: 'JWT',
  UsernamePassword: 'USERNAME_PASSWORD',
} as const;

export type ProvisioningLotPatchInputDtoDevicesMqttAuthTypeEnum =
  (typeof ProvisioningLotPatchInputDtoDevicesMqttAuthTypeEnum)[keyof typeof ProvisioningLotPatchInputDtoDevicesMqttAuthTypeEnum];

/**
 *
 * @export
 * @interface ProvisioningLotTokenUpdateInputDto
 */
export interface ProvisioningLotTokenUpdateInputDto {
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotTokenUpdateInputDto
   */
  availableNotBefore?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisioningLotTokenUpdateInputDto
   */
  availableNotAfter?: string;
}
/**
 *
 * @export
 * @interface ProvisioningLotsPageDto
 */
export interface ProvisioningLotsPageDto {
  /**
   *
   * @type {Array<ProvisioningLotDto>}
   * @memberof ProvisioningLotsPageDto
   */
  items: Array<ProvisioningLotDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof ProvisioningLotsPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface StringCommandPayloadDto
 */
export interface StringCommandPayloadDto {
  /**
   *
   * @type {string}
   * @memberof StringCommandPayloadDto
   */
  type: StringCommandPayloadDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof StringCommandPayloadDto
   */
  value: string;
}

export const StringCommandPayloadDtoTypeEnum = {
  StringCommandPayloadDto: 'StringCommandPayloadDto',
} as const;

export type StringCommandPayloadDtoTypeEnum =
  (typeof StringCommandPayloadDtoTypeEnum)[keyof typeof StringCommandPayloadDtoTypeEnum];

/**
 *
 * @export
 * @interface TwinAttributeValueDto
 */
export interface TwinAttributeValueDto {
  /**
   *
   * @type {TwinAttributeValueDtoValue}
   * @memberof TwinAttributeValueDto
   */
  value: TwinAttributeValueDtoValue;
  /**
   *
   * @type {string}
   * @memberof TwinAttributeValueDto
   */
  updatedAt: string;
}
/**
 * @type TwinAttributeValueDtoValue
 * @export
 */
export type TwinAttributeValueDtoValue =
  | Array<boolean>
  | Array<number>
  | Array<string>
  | boolean
  | number
  | string;

/**
 *
 * @export
 * @interface TwinDeltaDto
 */
export interface TwinDeltaDto {
  /**
   *
   * @type {string}
   * @memberof TwinDeltaDto
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof TwinDeltaDto
   */
  hubId: string;
  /**
   *
   * @type {Array<AttributeDeltaDto>}
   * @memberof TwinDeltaDto
   */
  delta: Array<AttributeDeltaDto>;
}
/**
 *
 * @export
 * @interface TwinDto
 */
export interface TwinDto {
  /**
   *
   * @type {{ [key: string]: TwinAttributeValueDto; }}
   * @memberof TwinDto
   */
  desired: { [key: string]: TwinAttributeValueDto };
  /**
   *
   * @type {{ [key: string]: TwinAttributeValueDto; }}
   * @memberof TwinDto
   */
  reported: { [key: string]: TwinAttributeValueDto };
  /**
   *
   * @type {string}
   * @memberof TwinDto
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof TwinDto
   */
  hubId: string;
}
/**
 *
 * @export
 * @interface TwinUpdateInputDto
 */
export interface TwinUpdateInputDto {
  /**
   *
   * @type {{ [key: string]: TwinAttributeValueDtoValue; }}
   * @memberof TwinUpdateInputDto
   */
  input: { [key: string]: TwinAttributeValueDtoValue };
}
/**
 *
 * @export
 * @interface TwinsDeltaPageDto
 */
export interface TwinsDeltaPageDto {
  /**
   *
   * @type {Array<TwinDeltaDto>}
   * @memberof TwinsDeltaPageDto
   */
  items: Array<TwinDeltaDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof TwinsDeltaPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface TwinsPageDto
 */
export interface TwinsPageDto {
  /**
   *
   * @type {Array<TwinDto>}
   * @memberof TwinsPageDto
   */
  items: Array<TwinDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof TwinsPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface TwinsPageQueryPostInputDto
 */
export interface TwinsPageQueryPostInputDto {
  /**
   *
   * @type {number}
   * @memberof TwinsPageQueryPostInputDto
   */
  skip?: number;
  /**
   *
   * @type {number}
   * @memberof TwinsPageQueryPostInputDto
   */
  take?: number;
  /**
   *
   * @type {boolean}
   * @memberof TwinsPageQueryPostInputDto
   */
  includeTotalCount?: boolean;
  /**
   *
   * @type {Array<TwinsSortCriterionDto>}
   * @memberof TwinsPageQueryPostInputDto
   */
  orderBy?: Array<TwinsSortCriterionDto>;
  /**
   *
   * @type {string}
   * @memberof TwinsPageQueryPostInputDto
   */
  status?: TwinsPageQueryPostInputDtoStatusEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof TwinsPageQueryPostInputDto
   */
  tags?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof TwinsPageQueryPostInputDto
   */
  devicesIds?: Array<string>;
}

export const TwinsPageQueryPostInputDtoStatusEnum = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
} as const;

export type TwinsPageQueryPostInputDtoStatusEnum =
  (typeof TwinsPageQueryPostInputDtoStatusEnum)[keyof typeof TwinsPageQueryPostInputDtoStatusEnum];

/**
 *
 * @export
 * @interface TwinsSortCriterionDto
 */
export interface TwinsSortCriterionDto {
  /**
   *
   * @type {string}
   * @memberof TwinsSortCriterionDto
   */
  key: TwinsSortCriterionDtoKeyEnum;
  /**
   *
   * @type {string}
   * @memberof TwinsSortCriterionDto
   */
  order: TwinsSortCriterionDtoOrderEnum;
}

export const TwinsSortCriterionDtoKeyEnum = {
  CreatedAt: 'createdAt',
  Status: 'status',
} as const;

export type TwinsSortCriterionDtoKeyEnum =
  (typeof TwinsSortCriterionDtoKeyEnum)[keyof typeof TwinsSortCriterionDtoKeyEnum];
export const TwinsSortCriterionDtoOrderEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type TwinsSortCriterionDtoOrderEnum =
  (typeof TwinsSortCriterionDtoOrderEnum)[keyof typeof TwinsSortCriterionDtoOrderEnum];

/**
 *
 * @export
 * @interface TwinsUpdateInputDto
 */
export interface TwinsUpdateInputDto {
  /**
   *
   * @type {{ [key: string]: TwinAttributeValueDtoValue; }}
   * @memberof TwinsUpdateInputDto
   */
  input: { [key: string]: TwinAttributeValueDtoValue };
  /**
   *
   * @type {TwinsUpdateQueryParamsDto}
   * @memberof TwinsUpdateInputDto
   */
  query?: TwinsUpdateQueryParamsDto;
}
/**
 *
 * @export
 * @interface TwinsUpdateQueryParamsDto
 */
export interface TwinsUpdateQueryParamsDto {
  /**
   *
   * @type {Array<string>}
   * @memberof TwinsUpdateQueryParamsDto
   */
  devicesIds?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof TwinsUpdateQueryParamsDto
   */
  devicesTags?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof TwinsUpdateQueryParamsDto
   */
  deviceStatus?: TwinsUpdateQueryParamsDtoDeviceStatusEnum;
}

export const TwinsUpdateQueryParamsDtoDeviceStatusEnum = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
} as const;

export type TwinsUpdateQueryParamsDtoDeviceStatusEnum =
  (typeof TwinsUpdateQueryParamsDtoDeviceStatusEnum)[keyof typeof TwinsUpdateQueryParamsDtoDeviceStatusEnum];

/**
 *
 * @export
 * @interface TwinsUpdateResultDto
 */
export interface TwinsUpdateResultDto {
  /**
   *
   * @type {number}
   * @memberof TwinsUpdateResultDto
   */
  nUpdated: number;
}
/**
 *
 * @export
 * @interface VerifyDeviceCredentialsInputDto
 */
export interface VerifyDeviceCredentialsInputDto {
  /**
   *
   * @type {string}
   * @memberof VerifyDeviceCredentialsInputDto
   */
  cloudApiPassword?: string;
  /**
   *
   * @type {MqttDeviceCredentialsDto}
   * @memberof VerifyDeviceCredentialsInputDto
   */
  mqttCredentials?: MqttDeviceCredentialsDto;
}

/**
 * CommandsApi - axios parameter creator
 * @export
 */
export const CommandsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {CommandCreateInputDto} commandCreateInputDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invokeCommandOnDevice: async (
      deviceId: string,
      commandCreateInputDto: CommandCreateInputDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('invokeCommandOnDevice', 'deviceId', deviceId);
      // verify required parameter 'commandCreateInputDto' is not null or undefined
      assertParamExists(
        'invokeCommandOnDevice',
        'commandCreateInputDto',
        commandCreateInputDto,
      );
      const localVarPath = `/api/devices/{deviceId}/commands`.replace(
        `{${'deviceId'}}`,
        encodeURIComponent(String(deviceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timeoutSec !== undefined) {
        localVarQueryParameter['timeoutSec'] = timeoutSec;
      }

      if (maxNAttempts !== undefined) {
        localVarQueryParameter['maxNAttempts'] = maxNAttempts;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        commandCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommandsApi - functional programming interface
 * @export
 */
export const CommandsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommandsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {CommandCreateInputDto} commandCreateInputDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async invokeCommandOnDevice(
      deviceId: string,
      commandCreateInputDto: CommandCreateInputDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CommandResultDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.invokeCommandOnDevice(
          deviceId,
          commandCreateInputDto,
          timeoutSec,
          maxNAttempts,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['CommandsApi.invokeCommandOnDevice']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CommandsApi - factory interface
 * @export
 */
export const CommandsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CommandsApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {CommandCreateInputDto} commandCreateInputDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invokeCommandOnDevice(
      deviceId: string,
      commandCreateInputDto: CommandCreateInputDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CommandResultDto> {
      return localVarFp
        .invokeCommandOnDevice(
          deviceId,
          commandCreateInputDto,
          timeoutSec,
          maxNAttempts,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommandsApi - object-oriented interface
 * @export
 * @class CommandsApi
 * @extends {BaseAPI}
 */
export class CommandsApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {CommandCreateInputDto} commandCreateInputDto
   * @param {number} [timeoutSec] Min value: 1, max value: 60
   * @param {number} [maxNAttempts] Min value: 1, max value: 10
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandsApi
   */
  public invokeCommandOnDevice(
    deviceId: string,
    commandCreateInputDto: CommandCreateInputDto,
    timeoutSec?: number,
    maxNAttempts?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return CommandsApiFp(this.configuration)
      .invokeCommandOnDevice(
        deviceId,
        commandCreateInputDto,
        timeoutSec,
        maxNAttempts,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * D2CDevicesApi - axios parameter creator
 * @export
 */
export const D2CDevicesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceInfo: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/d2c/devices/_/byToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * D2CDevicesApi - functional programming interface
 * @export
 */
export const D2CDevicesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    D2CDevicesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDeviceInfo(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeviceIdentityDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDeviceInfo(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['D2CDevicesApi.getDeviceInfo']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * D2CDevicesApi - factory interface
 * @export
 */
export const D2CDevicesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = D2CDevicesApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceInfo(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DeviceIdentityDto> {
      return localVarFp
        .getDeviceInfo(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * D2CDevicesApi - object-oriented interface
 * @export
 * @class D2CDevicesApi
 * @extends {BaseAPI}
 */
export class D2CDevicesApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof D2CDevicesApi
   */
  public getDeviceInfo(options?: RawAxiosRequestConfig) {
    return D2CDevicesApiFp(this.configuration)
      .getDeviceInfo(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DeviceConnectionLogApi - axios parameter creator
 * @export
 */
export const DeviceConnectionLogApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetConnectionLogsOrderByEnum>} [orderBy]
     * @param {Array<string>} [devicesIds] Filter logs by device identifiers, multiple device identifiers can be provided
     * @param {DateFilterDto} [triggeredAt] Filter logs by triggered date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectionLogs: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetConnectionLogsOrderByEnum>,
      devicesIds?: Array<string>,
      triggeredAt?: DateFilterDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/connection-logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (orderBy) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (devicesIds) {
        localVarQueryParameter['devicesIds'] = devicesIds;
      }

      if (triggeredAt !== undefined) {
        for (const [key, value] of Object.entries(triggeredAt)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeviceConnectionLogApi - functional programming interface
 * @export
 */
export const DeviceConnectionLogApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    DeviceConnectionLogApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetConnectionLogsOrderByEnum>} [orderBy]
     * @param {Array<string>} [devicesIds] Filter logs by device identifiers, multiple device identifiers can be provided
     * @param {DateFilterDto} [triggeredAt] Filter logs by triggered date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConnectionLogs(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetConnectionLogsOrderByEnum>,
      devicesIds?: Array<string>,
      triggeredAt?: DateFilterDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ConnectionLogsPageDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getConnectionLogs(
          skip,
          take,
          includeTotalCount,
          orderBy,
          devicesIds,
          triggeredAt,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DeviceConnectionLogApi.getConnectionLogs']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DeviceConnectionLogApi - factory interface
 * @export
 */
export const DeviceConnectionLogApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DeviceConnectionLogApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetConnectionLogsOrderByEnum>} [orderBy]
     * @param {Array<string>} [devicesIds] Filter logs by device identifiers, multiple device identifiers can be provided
     * @param {DateFilterDto} [triggeredAt] Filter logs by triggered date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectionLogs(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetConnectionLogsOrderByEnum>,
      devicesIds?: Array<string>,
      triggeredAt?: DateFilterDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ConnectionLogsPageDto> {
      return localVarFp
        .getConnectionLogs(
          skip,
          take,
          includeTotalCount,
          orderBy,
          devicesIds,
          triggeredAt,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DeviceConnectionLogApi - object-oriented interface
 * @export
 * @class DeviceConnectionLogApi
 * @extends {BaseAPI}
 */
export class DeviceConnectionLogApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {Array<GetConnectionLogsOrderByEnum>} [orderBy]
   * @param {Array<string>} [devicesIds] Filter logs by device identifiers, multiple device identifiers can be provided
   * @param {DateFilterDto} [triggeredAt] Filter logs by triggered date
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceConnectionLogApi
   */
  public getConnectionLogs(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    orderBy?: Array<GetConnectionLogsOrderByEnum>,
    devicesIds?: Array<string>,
    triggeredAt?: DateFilterDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DeviceConnectionLogApiFp(this.configuration)
      .getConnectionLogs(
        skip,
        take,
        includeTotalCount,
        orderBy,
        devicesIds,
        triggeredAt,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetConnectionLogsOrderByEnum = {
  DeviceIdAsc: 'deviceId[asc]',
  DeviceIdDesc: 'deviceId[desc]',
  TriggeredAtAsc: 'triggeredAt[asc]',
  TriggeredAtDesc: 'triggeredAt[desc]',
} as const;
export type GetConnectionLogsOrderByEnum =
  (typeof GetConnectionLogsOrderByEnum)[keyof typeof GetConnectionLogsOrderByEnum];

/**
 * DevicesApi - axios parameter creator
 * @export
 */
export const DevicesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDevice: async (
      deviceId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('deleteDevice', 'deviceId', deviceId);
      const localVarPath = `/api/devices/{deviceId}`.replace(
        `{${'deviceId'}}`,
        encodeURIComponent(String(deviceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {boolean} [refreshOnline] Refreshes device online status during retrieval
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice: async (
      deviceId: string,
      refreshOnline?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('getDevice', 'deviceId', deviceId);
      const localVarPath = `/api/devices/{deviceId}`.replace(
        `{${'deviceId'}}`,
        encodeURIComponent(String(deviceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (refreshOnline !== undefined) {
        localVarQueryParameter['refreshOnline'] = refreshOnline;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetDevicesOrderByEnum>} [orderBy]
     * @param {GetDevicesStatusEnum} [status]
     * @param {Array<string>} [tags]
     * @param {Array<string>} [devicesIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevices: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetDevicesOrderByEnum>,
      status?: GetDevicesStatusEnum,
      tags?: Array<string>,
      devicesIds?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/devices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (orderBy) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (tags) {
        localVarQueryParameter['tags'] = tags;
      }

      if (devicesIds) {
        localVarQueryParameter['devicesIds'] = devicesIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {DevicesPageQueryPostInputDto} devicesPageQueryPostInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGetDevices: async (
      devicesPageQueryPostInputDto: DevicesPageQueryPostInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'devicesPageQueryPostInputDto' is not null or undefined
      assertParamExists(
        'postGetDevices',
        'devicesPageQueryPostInputDto',
        devicesPageQueryPostInputDto,
      );
      const localVarPath = `/api/devices/_/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        devicesPageQueryPostInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {DevicePatchInputDto} devicePatchInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDevice: async (
      deviceId: string,
      devicePatchInputDto: DevicePatchInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('updateDevice', 'deviceId', deviceId);
      // verify required parameter 'devicePatchInputDto' is not null or undefined
      assertParamExists(
        'updateDevice',
        'devicePatchInputDto',
        devicePatchInputDto,
      );
      const localVarPath = `/api/devices/{deviceId}`.replace(
        `{${'deviceId'}}`,
        encodeURIComponent(String(deviceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        devicePatchInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {VerifyDeviceCredentialsInputDto} verifyDeviceCredentialsInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyDeviceCredentials: async (
      deviceId: string,
      verifyDeviceCredentialsInputDto: VerifyDeviceCredentialsInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('verifyDeviceCredentials', 'deviceId', deviceId);
      // verify required parameter 'verifyDeviceCredentialsInputDto' is not null or undefined
      assertParamExists(
        'verifyDeviceCredentials',
        'verifyDeviceCredentialsInputDto',
        verifyDeviceCredentialsInputDto,
      );
      const localVarPath =
        `/api/devices/{deviceId}/device-credentials/verification`.replace(
          `{${'deviceId'}}`,
          encodeURIComponent(String(deviceId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyDeviceCredentialsInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DevicesApi - functional programming interface
 * @export
 */
export const DevicesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DevicesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDevice(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDevice(
        deviceId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DevicesApi.deleteDevice']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {boolean} [refreshOnline] Refreshes device online status during retrieval
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDevice(
      deviceId: string,
      refreshOnline?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDevice(
        deviceId,
        refreshOnline,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DevicesApi.getDevice']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetDevicesOrderByEnum>} [orderBy]
     * @param {GetDevicesStatusEnum} [status]
     * @param {Array<string>} [tags]
     * @param {Array<string>} [devicesIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDevices(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetDevicesOrderByEnum>,
      status?: GetDevicesStatusEnum,
      tags?: Array<string>,
      devicesIds?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDevices(
        skip,
        take,
        includeTotalCount,
        orderBy,
        status,
        tags,
        devicesIds,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DevicesApi.getDevices']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {DevicesPageQueryPostInputDto} devicesPageQueryPostInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGetDevices(
      devicesPageQueryPostInputDto: DevicesPageQueryPostInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postGetDevices(
        devicesPageQueryPostInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DevicesApi.postGetDevices']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {DevicePatchInputDto} devicePatchInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDevice(
      deviceId: string,
      devicePatchInputDto: DevicePatchInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDevice(
        deviceId,
        devicePatchInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DevicesApi.updateDevice']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {VerifyDeviceCredentialsInputDto} verifyDeviceCredentialsInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyDeviceCredentials(
      deviceId: string,
      verifyDeviceCredentialsInputDto: VerifyDeviceCredentialsInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ActionResultDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyDeviceCredentials(
          deviceId,
          verifyDeviceCredentialsInputDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DevicesApi.verifyDeviceCredentials']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DevicesApi - factory interface
 * @export
 */
export const DevicesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DevicesApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDevice(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteDevice(deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {boolean} [refreshOnline] Refreshes device online status during retrieval
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice(
      deviceId: string,
      refreshOnline?: boolean,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DeviceDto> {
      return localVarFp
        .getDevice(deviceId, refreshOnline, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetDevicesOrderByEnum>} [orderBy]
     * @param {GetDevicesStatusEnum} [status]
     * @param {Array<string>} [tags]
     * @param {Array<string>} [devicesIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevices(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetDevicesOrderByEnum>,
      status?: GetDevicesStatusEnum,
      tags?: Array<string>,
      devicesIds?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DevicesPageDto> {
      return localVarFp
        .getDevices(
          skip,
          take,
          includeTotalCount,
          orderBy,
          status,
          tags,
          devicesIds,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {DevicesPageQueryPostInputDto} devicesPageQueryPostInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGetDevices(
      devicesPageQueryPostInputDto: DevicesPageQueryPostInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DevicesPageDto> {
      return localVarFp
        .postGetDevices(devicesPageQueryPostInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {DevicePatchInputDto} devicePatchInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDevice(
      deviceId: string,
      devicePatchInputDto: DevicePatchInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DeviceDto> {
      return localVarFp
        .updateDevice(deviceId, devicePatchInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {VerifyDeviceCredentialsInputDto} verifyDeviceCredentialsInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyDeviceCredentials(
      deviceId: string,
      verifyDeviceCredentialsInputDto: VerifyDeviceCredentialsInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ActionResultDto> {
      return localVarFp
        .verifyDeviceCredentials(
          deviceId,
          verifyDeviceCredentialsInputDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DevicesApi - object-oriented interface
 * @export
 * @class DevicesApi
 * @extends {BaseAPI}
 */
export class DevicesApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevicesApi
   */
  public deleteDevice(deviceId: string, options?: RawAxiosRequestConfig) {
    return DevicesApiFp(this.configuration)
      .deleteDevice(deviceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {boolean} [refreshOnline] Refreshes device online status during retrieval
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevicesApi
   */
  public getDevice(
    deviceId: string,
    refreshOnline?: boolean,
    options?: RawAxiosRequestConfig,
  ) {
    return DevicesApiFp(this.configuration)
      .getDevice(deviceId, refreshOnline, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {Array<GetDevicesOrderByEnum>} [orderBy]
   * @param {GetDevicesStatusEnum} [status]
   * @param {Array<string>} [tags]
   * @param {Array<string>} [devicesIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevicesApi
   */
  public getDevices(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    orderBy?: Array<GetDevicesOrderByEnum>,
    status?: GetDevicesStatusEnum,
    tags?: Array<string>,
    devicesIds?: Array<string>,
    options?: RawAxiosRequestConfig,
  ) {
    return DevicesApiFp(this.configuration)
      .getDevices(
        skip,
        take,
        includeTotalCount,
        orderBy,
        status,
        tags,
        devicesIds,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {DevicesPageQueryPostInputDto} devicesPageQueryPostInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevicesApi
   */
  public postGetDevices(
    devicesPageQueryPostInputDto: DevicesPageQueryPostInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DevicesApiFp(this.configuration)
      .postGetDevices(devicesPageQueryPostInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {DevicePatchInputDto} devicePatchInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevicesApi
   */
  public updateDevice(
    deviceId: string,
    devicePatchInputDto: DevicePatchInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DevicesApiFp(this.configuration)
      .updateDevice(deviceId, devicePatchInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {VerifyDeviceCredentialsInputDto} verifyDeviceCredentialsInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevicesApi
   */
  public verifyDeviceCredentials(
    deviceId: string,
    verifyDeviceCredentialsInputDto: VerifyDeviceCredentialsInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DevicesApiFp(this.configuration)
      .verifyDeviceCredentials(
        deviceId,
        verifyDeviceCredentialsInputDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetDevicesOrderByEnum = {
  CreatedAtAsc: 'createdAt[asc]',
  CreatedAtDesc: 'createdAt[desc]',
  StatusAsc: 'status[asc]',
  StatusDesc: 'status[desc]',
} as const;
export type GetDevicesOrderByEnum =
  (typeof GetDevicesOrderByEnum)[keyof typeof GetDevicesOrderByEnum];
/**
 * @export
 */
export const GetDevicesStatusEnum = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
} as const;
export type GetDevicesStatusEnum =
  (typeof GetDevicesStatusEnum)[keyof typeof GetDevicesStatusEnum];

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealth: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHealth(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetHealth200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getHealth(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HealthApi.getHealth']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealth(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetHealth200Response> {
      return localVarFp
        .getHealth(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthApi
   */
  public getHealth(options?: RawAxiosRequestConfig) {
    return HealthApiFp(this.configuration)
      .getHealth(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HubTokensApi - axios parameter creator
 * @export
 */
export const HubTokensApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {HubApiTokenCreateInputDto} hubApiTokenCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApiToken: async (
      hubId: string,
      hubApiTokenCreateInputDto: HubApiTokenCreateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('createApiToken', 'hubId', hubId);
      // verify required parameter 'hubApiTokenCreateInputDto' is not null or undefined
      assertParamExists(
        'createApiToken',
        'hubApiTokenCreateInputDto',
        hubApiTokenCreateInputDto,
      );
      const localVarPath = `/api/hubs/{hubId}/api-tokens`.replace(
        `{${'hubId'}}`,
        encodeURIComponent(String(hubId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        hubApiTokenCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApiToken: async (
      hubId: string,
      apiTokenId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('deleteApiToken', 'hubId', hubId);
      // verify required parameter 'apiTokenId' is not null or undefined
      assertParamExists('deleteApiToken', 'apiTokenId', apiTokenId);
      const localVarPath = `/api/hubs/{hubId}/api-tokens/{apiTokenId}`
        .replace(`{${'hubId'}}`, encodeURIComponent(String(hubId)))
        .replace(`{${'apiTokenId'}}`, encodeURIComponent(String(apiTokenId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiToken: async (
      hubId: string,
      apiTokenId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('getApiToken', 'hubId', hubId);
      // verify required parameter 'apiTokenId' is not null or undefined
      assertParamExists('getApiToken', 'apiTokenId', apiTokenId);
      const localVarPath = `/api/hubs/{hubId}/api-tokens/{apiTokenId}`
        .replace(`{${'hubId'}}`, encodeURIComponent(String(hubId)))
        .replace(`{${'apiTokenId'}}`, encodeURIComponent(String(apiTokenId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiTokens: async (
      hubId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('getApiTokens', 'hubId', hubId);
      const localVarPath = `/api/hubs/{hubId}/api-tokens`.replace(
        `{${'hubId'}}`,
        encodeURIComponent(String(hubId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HubTokensApi - functional programming interface
 * @export
 */
export const HubTokensApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    HubTokensApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {HubApiTokenCreateInputDto} hubApiTokenCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createApiToken(
      hubId: string,
      hubApiTokenCreateInputDto: HubApiTokenCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HubApiTokenDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createApiToken(
        hubId,
        hubApiTokenCreateInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubTokensApi.createApiToken']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteApiToken(
      hubId: string,
      apiTokenId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiToken(
        hubId,
        apiTokenId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubTokensApi.deleteApiToken']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApiToken(
      hubId: string,
      apiTokenId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HubApiTokenDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApiToken(
        hubId,
        apiTokenId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubTokensApi.getApiToken']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApiTokens(
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<HubApiTokenDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTokens(
        hubId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubTokensApi.getApiTokens']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HubTokensApi - factory interface
 * @export
 */
export const HubTokensApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HubTokensApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {HubApiTokenCreateInputDto} hubApiTokenCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApiToken(
      hubId: string,
      hubApiTokenCreateInputDto: HubApiTokenCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HubApiTokenDto> {
      return localVarFp
        .createApiToken(hubId, hubApiTokenCreateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApiToken(
      hubId: string,
      apiTokenId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteApiToken(hubId, apiTokenId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiToken(
      hubId: string,
      apiTokenId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HubApiTokenDto> {
      return localVarFp
        .getApiToken(hubId, apiTokenId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiTokens(
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<HubApiTokenDto>> {
      return localVarFp
        .getApiTokens(hubId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HubTokensApi - object-oriented interface
 * @export
 * @class HubTokensApi
 * @extends {BaseAPI}
 */
export class HubTokensApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {string} hubId
   * @param {HubApiTokenCreateInputDto} hubApiTokenCreateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubTokensApi
   */
  public createApiToken(
    hubId: string,
    hubApiTokenCreateInputDto: HubApiTokenCreateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return HubTokensApiFp(this.configuration)
      .createApiToken(hubId, hubApiTokenCreateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} hubId
   * @param {string} apiTokenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubTokensApi
   */
  public deleteApiToken(
    hubId: string,
    apiTokenId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return HubTokensApiFp(this.configuration)
      .deleteApiToken(hubId, apiTokenId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} hubId
   * @param {string} apiTokenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubTokensApi
   */
  public getApiToken(
    hubId: string,
    apiTokenId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return HubTokensApiFp(this.configuration)
      .getApiToken(hubId, apiTokenId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} hubId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubTokensApi
   */
  public getApiTokens(hubId: string, options?: RawAxiosRequestConfig) {
    return HubTokensApiFp(this.configuration)
      .getApiTokens(hubId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HubsApi - axios parameter creator
 * @export
 */
export const HubsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {HubCreateInputDto} hubCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createHub: async (
      hubCreateInputDto: HubCreateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubCreateInputDto' is not null or undefined
      assertParamExists('createHub', 'hubCreateInputDto', hubCreateInputDto);
      const localVarPath = `/api/hubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        hubCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteHub: async (
      hubId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('deleteHub', 'hubId', hubId);
      const localVarPath = `/api/hubs/{hubId}`.replace(
        `{${'hubId'}}`,
        encodeURIComponent(String(hubId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHub: async (
      hubId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('getHub', 'hubId', hubId);
      const localVarPath = `/api/hubs/{hubId}`.replace(
        `{${'hubId'}}`,
        encodeURIComponent(String(hubId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetHubsOrderByEnum>} [orderBy]
     * @param {DateFilterDto} [createdAt]
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHubs: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetHubsOrderByEnum>,
      createdAt?: DateFilterDto,
      search?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/hubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (orderBy) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (createdAt !== undefined) {
        for (const [key, value] of Object.entries(createdAt)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {HubUpdateInputDto} hubUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateHub: async (
      hubId: string,
      hubUpdateInputDto: HubUpdateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('updateHub', 'hubId', hubId);
      // verify required parameter 'hubUpdateInputDto' is not null or undefined
      assertParamExists('updateHub', 'hubUpdateInputDto', hubUpdateInputDto);
      const localVarPath = `/api/hubs/{hubId}`.replace(
        `{${'hubId'}}`,
        encodeURIComponent(String(hubId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        hubUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {HubCreateInputDto} hubCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertHub: async (
      hubCreateInputDto: HubCreateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubCreateInputDto' is not null or undefined
      assertParamExists('upsertHub', 'hubCreateInputDto', hubCreateInputDto);
      const localVarPath = `/api/hubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        hubCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HubsApi - functional programming interface
 * @export
 */
export const HubsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HubsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {HubCreateInputDto} hubCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createHub(
      hubCreateInputDto: HubCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HubDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createHub(
        hubCreateInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubsApi.createHub']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteHub(
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHub(
        hubId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubsApi.deleteHub']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHub(
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HubDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHub(
        hubId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubsApi.getHub']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetHubsOrderByEnum>} [orderBy]
     * @param {DateFilterDto} [createdAt]
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHubs(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetHubsOrderByEnum>,
      createdAt?: DateFilterDto,
      search?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HubsPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHubs(
        skip,
        take,
        includeTotalCount,
        orderBy,
        createdAt,
        search,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubsApi.getHubs']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {HubUpdateInputDto} hubUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateHub(
      hubId: string,
      hubUpdateInputDto: HubUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HubDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateHub(
        hubId,
        hubUpdateInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubsApi.updateHub']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {HubCreateInputDto} hubCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertHub(
      hubCreateInputDto: HubCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HubDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertHub(
        hubCreateInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HubsApi.upsertHub']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HubsApi - factory interface
 * @export
 */
export const HubsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HubsApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {HubCreateInputDto} hubCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createHub(
      hubCreateInputDto: HubCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HubDto> {
      return localVarFp
        .createHub(hubCreateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteHub(
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteHub(hubId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHub(
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HubDto> {
      return localVarFp
        .getHub(hubId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetHubsOrderByEnum>} [orderBy]
     * @param {DateFilterDto} [createdAt]
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHubs(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetHubsOrderByEnum>,
      createdAt?: DateFilterDto,
      search?: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HubsPageDto> {
      return localVarFp
        .getHubs(
          skip,
          take,
          includeTotalCount,
          orderBy,
          createdAt,
          search,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} hubId
     * @param {HubUpdateInputDto} hubUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateHub(
      hubId: string,
      hubUpdateInputDto: HubUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HubDto> {
      return localVarFp
        .updateHub(hubId, hubUpdateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {HubCreateInputDto} hubCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertHub(
      hubCreateInputDto: HubCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HubDto> {
      return localVarFp
        .upsertHub(hubCreateInputDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HubsApi - object-oriented interface
 * @export
 * @class HubsApi
 * @extends {BaseAPI}
 */
export class HubsApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {HubCreateInputDto} hubCreateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubsApi
   */
  public createHub(
    hubCreateInputDto: HubCreateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return HubsApiFp(this.configuration)
      .createHub(hubCreateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} hubId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubsApi
   */
  public deleteHub(hubId: string, options?: RawAxiosRequestConfig) {
    return HubsApiFp(this.configuration)
      .deleteHub(hubId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} hubId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubsApi
   */
  public getHub(hubId: string, options?: RawAxiosRequestConfig) {
    return HubsApiFp(this.configuration)
      .getHub(hubId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {Array<GetHubsOrderByEnum>} [orderBy]
   * @param {DateFilterDto} [createdAt]
   * @param {string} [search]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubsApi
   */
  public getHubs(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    orderBy?: Array<GetHubsOrderByEnum>,
    createdAt?: DateFilterDto,
    search?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return HubsApiFp(this.configuration)
      .getHubs(
        skip,
        take,
        includeTotalCount,
        orderBy,
        createdAt,
        search,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} hubId
   * @param {HubUpdateInputDto} hubUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubsApi
   */
  public updateHub(
    hubId: string,
    hubUpdateInputDto: HubUpdateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return HubsApiFp(this.configuration)
      .updateHub(hubId, hubUpdateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {HubCreateInputDto} hubCreateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HubsApi
   */
  public upsertHub(
    hubCreateInputDto: HubCreateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return HubsApiFp(this.configuration)
      .upsertHub(hubCreateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetHubsOrderByEnum = {
  CreatedAtAsc: 'createdAt[asc]',
  CreatedAtDesc: 'createdAt[desc]',
  NameAsc: 'name[asc]',
  NameDesc: 'name[desc]',
} as const;
export type GetHubsOrderByEnum =
  (typeof GetHubsOrderByEnum)[keyof typeof GetHubsOrderByEnum];

/**
 * ManufacturingLogsApi - axios parameter creator
 * @export
 */
export const ManufacturingLogsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {LogEntryCreateInputDto} logEntryCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogEntry: async (
      logEntryCreateInputDto: LogEntryCreateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'logEntryCreateInputDto' is not null or undefined
      assertParamExists(
        'createLogEntry',
        'logEntryCreateInputDto',
        logEntryCreateInputDto,
      );
      const localVarPath = `/api/logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        logEntryCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {LogBulkCreateInputDto} logBulkCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogsBulk: async (
      logBulkCreateInputDto: LogBulkCreateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'logBulkCreateInputDto' is not null or undefined
      assertParamExists(
        'createLogsBulk',
        'logBulkCreateInputDto',
        logBulkCreateInputDto,
      );
      const localVarPath = `/api/logs/bulk`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        logBulkCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetLogsOrderByEnum>} [orderBy]
     * @param {string} [search] searches text within &#x60;message&#x60; attribute
     * @param {string} [hubId]
     * @param {number} [provisioningLotId]
     * @param {string} [trackingId]
     * @param {string} [deviceId]
     * @param {GetLogsLogLevelEnum} [logLevel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogs: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetLogsOrderByEnum>,
      search?: string,
      hubId?: string,
      provisioningLotId?: number,
      trackingId?: string,
      deviceId?: string,
      logLevel?: GetLogsLogLevelEnum,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (orderBy) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      if (hubId !== undefined) {
        localVarQueryParameter['hubId'] = hubId;
      }

      if (provisioningLotId !== undefined) {
        localVarQueryParameter['provisioningLotId'] = provisioningLotId;
      }

      if (trackingId !== undefined) {
        localVarQueryParameter['trackingId'] = trackingId;
      }

      if (deviceId !== undefined) {
        localVarQueryParameter['deviceId'] = deviceId;
      }

      if (logLevel !== undefined) {
        localVarQueryParameter['logLevel'] = logLevel;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManufacturingLogsApi - functional programming interface
 * @export
 */
export const ManufacturingLogsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ManufacturingLogsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {LogEntryCreateInputDto} logEntryCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLogEntry(
      logEntryCreateInputDto: LogEntryCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogEntryDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createLogEntry(
        logEntryCreateInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ManufacturingLogsApi.createLogEntry']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {LogBulkCreateInputDto} logBulkCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLogsBulk(
      logBulkCreateInputDto: LogBulkCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createLogsBulk(
        logBulkCreateInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ManufacturingLogsApi.createLogsBulk']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetLogsOrderByEnum>} [orderBy]
     * @param {string} [search] searches text within &#x60;message&#x60; attribute
     * @param {string} [hubId]
     * @param {number} [provisioningLotId]
     * @param {string} [trackingId]
     * @param {string} [deviceId]
     * @param {GetLogsLogLevelEnum} [logLevel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLogs(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetLogsOrderByEnum>,
      search?: string,
      hubId?: string,
      provisioningLotId?: number,
      trackingId?: string,
      deviceId?: string,
      logLevel?: GetLogsLogLevelEnum,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LogEntriesPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLogs(
        skip,
        take,
        includeTotalCount,
        orderBy,
        search,
        hubId,
        provisioningLotId,
        trackingId,
        deviceId,
        logLevel,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ManufacturingLogsApi.getLogs']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ManufacturingLogsApi - factory interface
 * @export
 */
export const ManufacturingLogsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ManufacturingLogsApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {LogEntryCreateInputDto} logEntryCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogEntry(
      logEntryCreateInputDto: LogEntryCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LogEntryDto> {
      return localVarFp
        .createLogEntry(logEntryCreateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {LogBulkCreateInputDto} logBulkCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogsBulk(
      logBulkCreateInputDto: LogBulkCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .createLogsBulk(logBulkCreateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetLogsOrderByEnum>} [orderBy]
     * @param {string} [search] searches text within &#x60;message&#x60; attribute
     * @param {string} [hubId]
     * @param {number} [provisioningLotId]
     * @param {string} [trackingId]
     * @param {string} [deviceId]
     * @param {GetLogsLogLevelEnum} [logLevel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogs(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetLogsOrderByEnum>,
      search?: string,
      hubId?: string,
      provisioningLotId?: number,
      trackingId?: string,
      deviceId?: string,
      logLevel?: GetLogsLogLevelEnum,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LogEntriesPageDto> {
      return localVarFp
        .getLogs(
          skip,
          take,
          includeTotalCount,
          orderBy,
          search,
          hubId,
          provisioningLotId,
          trackingId,
          deviceId,
          logLevel,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ManufacturingLogsApi - object-oriented interface
 * @export
 * @class ManufacturingLogsApi
 * @extends {BaseAPI}
 */
export class ManufacturingLogsApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {LogEntryCreateInputDto} logEntryCreateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturingLogsApi
   */
  public createLogEntry(
    logEntryCreateInputDto: LogEntryCreateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ManufacturingLogsApiFp(this.configuration)
      .createLogEntry(logEntryCreateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {LogBulkCreateInputDto} logBulkCreateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturingLogsApi
   */
  public createLogsBulk(
    logBulkCreateInputDto: LogBulkCreateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ManufacturingLogsApiFp(this.configuration)
      .createLogsBulk(logBulkCreateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {Array<GetLogsOrderByEnum>} [orderBy]
   * @param {string} [search] searches text within &#x60;message&#x60; attribute
   * @param {string} [hubId]
   * @param {number} [provisioningLotId]
   * @param {string} [trackingId]
   * @param {string} [deviceId]
   * @param {GetLogsLogLevelEnum} [logLevel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturingLogsApi
   */
  public getLogs(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    orderBy?: Array<GetLogsOrderByEnum>,
    search?: string,
    hubId?: string,
    provisioningLotId?: number,
    trackingId?: string,
    deviceId?: string,
    logLevel?: GetLogsLogLevelEnum,
    options?: RawAxiosRequestConfig,
  ) {
    return ManufacturingLogsApiFp(this.configuration)
      .getLogs(
        skip,
        take,
        includeTotalCount,
        orderBy,
        search,
        hubId,
        provisioningLotId,
        trackingId,
        deviceId,
        logLevel,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetLogsOrderByEnum = {
  TriggeredAtAsc: 'triggeredAt[asc]',
  TriggeredAtDesc: 'triggeredAt[desc]',
  ReceivedAtAsc: 'receivedAt[asc]',
  ReceivedAtDesc: 'receivedAt[desc]',
} as const;
export type GetLogsOrderByEnum =
  (typeof GetLogsOrderByEnum)[keyof typeof GetLogsOrderByEnum];
/**
 * @export
 */
export const GetLogsLogLevelEnum = {
  Info: 'INFO',
  Error: 'ERROR',
} as const;
export type GetLogsLogLevelEnum =
  (typeof GetLogsLogLevelEnum)[keyof typeof GetLogsLogLevelEnum];

/**
 * ProvisioningApi - axios parameter creator
 * @export
 */
export const ProvisioningApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Registers new device or returns existing one
     * @param {DeviceCreateInputDto} deviceCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDevice: async (
      deviceCreateInputDto: DeviceCreateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceCreateInputDto' is not null or undefined
      assertParamExists(
        'createDevice',
        'deviceCreateInputDto',
        deviceCreateInputDto,
      );
      const localVarPath = `/api/devices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deviceCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Registers new set of devices or returns existing ones
     * @param {DeviceCreateBatchInputDto} deviceCreateBatchInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManyDevices: async (
      deviceCreateBatchInputDto: DeviceCreateBatchInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceCreateBatchInputDto' is not null or undefined
      assertParamExists(
        'createManyDevices',
        'deviceCreateBatchInputDto',
        deviceCreateBatchInputDto,
      );
      const localVarPath = `/api/devices/batch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deviceCreateBatchInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceAsProvisioner: async (
      deviceId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('getDeviceAsProvisioner', 'deviceId', deviceId);
      const localVarPath = `/api/devices/{deviceId}/as-provisioner`.replace(
        `{${'deviceId'}}`,
        encodeURIComponent(String(deviceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwinAsProvisioner: async (
      deviceId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('getTwinAsProvisioner', 'deviceId', deviceId);
      const localVarPath =
        `/api/devices/{deviceId}/twin/as-provisioner`.replace(
          `{${'deviceId'}}`,
          encodeURIComponent(String(deviceId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {CommandCreateInputDto} commandCreateInputDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invokeCommandOnDeviceAsProvisioner: async (
      deviceId: string,
      commandCreateInputDto: CommandCreateInputDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists(
        'invokeCommandOnDeviceAsProvisioner',
        'deviceId',
        deviceId,
      );
      // verify required parameter 'commandCreateInputDto' is not null or undefined
      assertParamExists(
        'invokeCommandOnDeviceAsProvisioner',
        'commandCreateInputDto',
        commandCreateInputDto,
      );
      const localVarPath =
        `/api/devices/{deviceId}/commands/as-provisioner`.replace(
          `{${'deviceId'}}`,
          encodeURIComponent(String(deviceId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timeoutSec !== undefined) {
        localVarQueryParameter['timeoutSec'] = timeoutSec;
      }

      if (maxNAttempts !== undefined) {
        localVarQueryParameter['maxNAttempts'] = maxNAttempts;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        commandCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProvisioningApi - functional programming interface
 * @export
 */
export const ProvisioningApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ProvisioningApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Registers new device or returns existing one
     * @param {DeviceCreateInputDto} deviceCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDevice(
      deviceCreateInputDto: DeviceCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ConfidentialDeviceDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDevice(
        deviceCreateInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningApi.createDevice']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Registers new set of devices or returns existing ones
     * @param {DeviceCreateBatchInputDto} deviceCreateBatchInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createManyDevices(
      deviceCreateBatchInputDto: DeviceCreateBatchInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ConfidentialDeviceDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createManyDevices(
          deviceCreateBatchInputDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningApi.createManyDevices']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDeviceAsProvisioner(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDeviceAsProvisioner(
          deviceId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningApi.getDeviceAsProvisioner']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTwinAsProvisioner(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwinDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTwinAsProvisioner(deviceId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningApi.getTwinAsProvisioner']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {CommandCreateInputDto} commandCreateInputDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async invokeCommandOnDeviceAsProvisioner(
      deviceId: string,
      commandCreateInputDto: CommandCreateInputDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CommandResultDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.invokeCommandOnDeviceAsProvisioner(
          deviceId,
          commandCreateInputDto,
          timeoutSec,
          maxNAttempts,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ProvisioningApi.invokeCommandOnDeviceAsProvisioner'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ProvisioningApi - factory interface
 * @export
 */
export const ProvisioningApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ProvisioningApiFp(configuration);
  return {
    /**
     *
     * @summary Registers new device or returns existing one
     * @param {DeviceCreateInputDto} deviceCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDevice(
      deviceCreateInputDto: DeviceCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ConfidentialDeviceDto> {
      return localVarFp
        .createDevice(deviceCreateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Registers new set of devices or returns existing ones
     * @param {DeviceCreateBatchInputDto} deviceCreateBatchInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManyDevices(
      deviceCreateBatchInputDto: DeviceCreateBatchInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ConfidentialDeviceDto>> {
      return localVarFp
        .createManyDevices(deviceCreateBatchInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceAsProvisioner(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DeviceDto> {
      return localVarFp
        .getDeviceAsProvisioner(deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwinAsProvisioner(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TwinDto> {
      return localVarFp
        .getTwinAsProvisioner(deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {CommandCreateInputDto} commandCreateInputDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invokeCommandOnDeviceAsProvisioner(
      deviceId: string,
      commandCreateInputDto: CommandCreateInputDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CommandResultDto> {
      return localVarFp
        .invokeCommandOnDeviceAsProvisioner(
          deviceId,
          commandCreateInputDto,
          timeoutSec,
          maxNAttempts,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ProvisioningApi - object-oriented interface
 * @export
 * @class ProvisioningApi
 * @extends {BaseAPI}
 */
export class ProvisioningApi extends BaseAPI {
  /**
   *
   * @summary Registers new device or returns existing one
   * @param {DeviceCreateInputDto} deviceCreateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningApi
   */
  public createDevice(
    deviceCreateInputDto: DeviceCreateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningApiFp(this.configuration)
      .createDevice(deviceCreateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Registers new set of devices or returns existing ones
   * @param {DeviceCreateBatchInputDto} deviceCreateBatchInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningApi
   */
  public createManyDevices(
    deviceCreateBatchInputDto: DeviceCreateBatchInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningApiFp(this.configuration)
      .createManyDevices(deviceCreateBatchInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningApi
   */
  public getDeviceAsProvisioner(
    deviceId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningApiFp(this.configuration)
      .getDeviceAsProvisioner(deviceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningApi
   */
  public getTwinAsProvisioner(
    deviceId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningApiFp(this.configuration)
      .getTwinAsProvisioner(deviceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {CommandCreateInputDto} commandCreateInputDto
   * @param {number} [timeoutSec] Min value: 1, max value: 60
   * @param {number} [maxNAttempts] Min value: 1, max value: 10
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningApi
   */
  public invokeCommandOnDeviceAsProvisioner(
    deviceId: string,
    commandCreateInputDto: CommandCreateInputDto,
    timeoutSec?: number,
    maxNAttempts?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningApiFp(this.configuration)
      .invokeCommandOnDeviceAsProvisioner(
        deviceId,
        commandCreateInputDto,
        timeoutSec,
        maxNAttempts,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ProvisioningLotsApi - axios parameter creator
 * @export
 */
export const ProvisioningLotsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create a new provisioning lot
     * @param {string} hubId
     * @param {ProvisioningLotCreateInputDto} provisioningLotCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProvisioningLot: async (
      hubId: string,
      provisioningLotCreateInputDto: ProvisioningLotCreateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('createProvisioningLot', 'hubId', hubId);
      // verify required parameter 'provisioningLotCreateInputDto' is not null or undefined
      assertParamExists(
        'createProvisioningLot',
        'provisioningLotCreateInputDto',
        provisioningLotCreateInputDto,
      );
      const localVarPath = `/api/hubs/{hubId}/provisioning-lots`.replace(
        `{${'hubId'}}`,
        encodeURIComponent(String(hubId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        provisioningLotCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete an existing provisioning lot
     * @param {number} lotId
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProvisioningLot: async (
      lotId: number,
      hubId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'lotId' is not null or undefined
      assertParamExists('deleteProvisioningLot', 'lotId', lotId);
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('deleteProvisioningLot', 'hubId', hubId);
      const localVarPath = `/api/hubs/{hubId}/provisioning-lots/{lotId}`
        .replace(`{${'lotId'}}`, encodeURIComponent(String(lotId)))
        .replace(`{${'hubId'}}`, encodeURIComponent(String(hubId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyProvisioningLot: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/provisioning-lots/my`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get an existing provisioning lot
     * @param {number} lotId
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProvisioningLot: async (
      lotId: number,
      hubId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'lotId' is not null or undefined
      assertParamExists('getProvisioningLot', 'lotId', lotId);
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('getProvisioningLot', 'hubId', hubId);
      const localVarPath = `/api/hubs/{hubId}/provisioning-lots/{lotId}`
        .replace(`{${'lotId'}}`, encodeURIComponent(String(lotId)))
        .replace(`{${'hubId'}}`, encodeURIComponent(String(hubId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get an existing paginated provisioning lots
     * @param {string} hubId
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetProvisioningLotsOrderByEnum>} [orderBy]
     * @param {DateFilterDto} [createdAt]
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProvisioningLots: async (
      hubId: string,
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetProvisioningLotsOrderByEnum>,
      createdAt?: DateFilterDto,
      search?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('getProvisioningLots', 'hubId', hubId);
      const localVarPath = `/api/hubs/{hubId}/provisioning-lots`.replace(
        `{${'hubId'}}`,
        encodeURIComponent(String(hubId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (orderBy) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (createdAt !== undefined) {
        for (const [key, value] of Object.entries(createdAt)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Partial update an existing provisioning lot
     * @param {number} lotId
     * @param {string} hubId
     * @param {ProvisioningLotPatchInputDto} provisioningLotPatchInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProvisioningLot: async (
      lotId: number,
      hubId: string,
      provisioningLotPatchInputDto: ProvisioningLotPatchInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'lotId' is not null or undefined
      assertParamExists('updateProvisioningLot', 'lotId', lotId);
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('updateProvisioningLot', 'hubId', hubId);
      // verify required parameter 'provisioningLotPatchInputDto' is not null or undefined
      assertParamExists(
        'updateProvisioningLot',
        'provisioningLotPatchInputDto',
        provisioningLotPatchInputDto,
      );
      const localVarPath = `/api/hubs/{hubId}/provisioning-lots/{lotId}`
        .replace(`{${'lotId'}}`, encodeURIComponent(String(lotId)))
        .replace(`{${'hubId'}}`, encodeURIComponent(String(hubId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        provisioningLotPatchInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Issue new provisioning token.
     * @param {number} lotId
     * @param {string} hubId
     * @param {ProvisioningLotTokenUpdateInputDto} provisioningLotTokenUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProvisioningToken: async (
      lotId: number,
      hubId: string,
      provisioningLotTokenUpdateInputDto: ProvisioningLotTokenUpdateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'lotId' is not null or undefined
      assertParamExists('updateProvisioningToken', 'lotId', lotId);
      // verify required parameter 'hubId' is not null or undefined
      assertParamExists('updateProvisioningToken', 'hubId', hubId);
      // verify required parameter 'provisioningLotTokenUpdateInputDto' is not null or undefined
      assertParamExists(
        'updateProvisioningToken',
        'provisioningLotTokenUpdateInputDto',
        provisioningLotTokenUpdateInputDto,
      );
      const localVarPath = `/api/hubs/{hubId}/provisioning-lots/{lotId}/token`
        .replace(`{${'lotId'}}`, encodeURIComponent(String(lotId)))
        .replace(`{${'hubId'}}`, encodeURIComponent(String(hubId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        provisioningLotTokenUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProvisioningLotsApi - functional programming interface
 * @export
 */
export const ProvisioningLotsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ProvisioningLotsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a new provisioning lot
     * @param {string} hubId
     * @param {ProvisioningLotCreateInputDto} provisioningLotCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProvisioningLot(
      hubId: string,
      provisioningLotCreateInputDto: ProvisioningLotCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProvisioningLotDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createProvisioningLot(
          hubId,
          provisioningLotCreateInputDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningLotsApi.createProvisioningLot']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete an existing provisioning lot
     * @param {number} lotId
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteProvisioningLot(
      lotId: number,
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteProvisioningLot(
          lotId,
          hubId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningLotsApi.deleteProvisioningLot']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMyProvisioningLot(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProvisioningLotDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMyProvisioningLot(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningLotsApi.getMyProvisioningLot']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get an existing provisioning lot
     * @param {number} lotId
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProvisioningLot(
      lotId: number,
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProvisioningLotDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProvisioningLot(
          lotId,
          hubId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningLotsApi.getProvisioningLot']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get an existing paginated provisioning lots
     * @param {string} hubId
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetProvisioningLotsOrderByEnum>} [orderBy]
     * @param {DateFilterDto} [createdAt]
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProvisioningLots(
      hubId: string,
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetProvisioningLotsOrderByEnum>,
      createdAt?: DateFilterDto,
      search?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProvisioningLotsPageDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProvisioningLots(
          hubId,
          skip,
          take,
          includeTotalCount,
          orderBy,
          createdAt,
          search,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningLotsApi.getProvisioningLots']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Partial update an existing provisioning lot
     * @param {number} lotId
     * @param {string} hubId
     * @param {ProvisioningLotPatchInputDto} provisioningLotPatchInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProvisioningLot(
      lotId: number,
      hubId: string,
      provisioningLotPatchInputDto: ProvisioningLotPatchInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProvisioningLotDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateProvisioningLot(
          lotId,
          hubId,
          provisioningLotPatchInputDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningLotsApi.updateProvisioningLot']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Issue new provisioning token.
     * @param {number} lotId
     * @param {string} hubId
     * @param {ProvisioningLotTokenUpdateInputDto} provisioningLotTokenUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProvisioningToken(
      lotId: number,
      hubId: string,
      provisioningLotTokenUpdateInputDto: ProvisioningLotTokenUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProvisioningLotDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateProvisioningToken(
          lotId,
          hubId,
          provisioningLotTokenUpdateInputDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ProvisioningLotsApi.updateProvisioningToken']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ProvisioningLotsApi - factory interface
 * @export
 */
export const ProvisioningLotsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ProvisioningLotsApiFp(configuration);
  return {
    /**
     *
     * @summary Create a new provisioning lot
     * @param {string} hubId
     * @param {ProvisioningLotCreateInputDto} provisioningLotCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProvisioningLot(
      hubId: string,
      provisioningLotCreateInputDto: ProvisioningLotCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ProvisioningLotDto> {
      return localVarFp
        .createProvisioningLot(hubId, provisioningLotCreateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete an existing provisioning lot
     * @param {number} lotId
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProvisioningLot(
      lotId: number,
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteProvisioningLot(lotId, hubId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyProvisioningLot(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ProvisioningLotDto> {
      return localVarFp
        .getMyProvisioningLot(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get an existing provisioning lot
     * @param {number} lotId
     * @param {string} hubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProvisioningLot(
      lotId: number,
      hubId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ProvisioningLotDto> {
      return localVarFp
        .getProvisioningLot(lotId, hubId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get an existing paginated provisioning lots
     * @param {string} hubId
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetProvisioningLotsOrderByEnum>} [orderBy]
     * @param {DateFilterDto} [createdAt]
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProvisioningLots(
      hubId: string,
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetProvisioningLotsOrderByEnum>,
      createdAt?: DateFilterDto,
      search?: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ProvisioningLotsPageDto> {
      return localVarFp
        .getProvisioningLots(
          hubId,
          skip,
          take,
          includeTotalCount,
          orderBy,
          createdAt,
          search,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Partial update an existing provisioning lot
     * @param {number} lotId
     * @param {string} hubId
     * @param {ProvisioningLotPatchInputDto} provisioningLotPatchInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProvisioningLot(
      lotId: number,
      hubId: string,
      provisioningLotPatchInputDto: ProvisioningLotPatchInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ProvisioningLotDto> {
      return localVarFp
        .updateProvisioningLot(
          lotId,
          hubId,
          provisioningLotPatchInputDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Issue new provisioning token.
     * @param {number} lotId
     * @param {string} hubId
     * @param {ProvisioningLotTokenUpdateInputDto} provisioningLotTokenUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProvisioningToken(
      lotId: number,
      hubId: string,
      provisioningLotTokenUpdateInputDto: ProvisioningLotTokenUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ProvisioningLotDto> {
      return localVarFp
        .updateProvisioningToken(
          lotId,
          hubId,
          provisioningLotTokenUpdateInputDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ProvisioningLotsApi - object-oriented interface
 * @export
 * @class ProvisioningLotsApi
 * @extends {BaseAPI}
 */
export class ProvisioningLotsApi extends BaseAPI {
  /**
   *
   * @summary Create a new provisioning lot
   * @param {string} hubId
   * @param {ProvisioningLotCreateInputDto} provisioningLotCreateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningLotsApi
   */
  public createProvisioningLot(
    hubId: string,
    provisioningLotCreateInputDto: ProvisioningLotCreateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningLotsApiFp(this.configuration)
      .createProvisioningLot(hubId, provisioningLotCreateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete an existing provisioning lot
   * @param {number} lotId
   * @param {string} hubId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningLotsApi
   */
  public deleteProvisioningLot(
    lotId: number,
    hubId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningLotsApiFp(this.configuration)
      .deleteProvisioningLot(lotId, hubId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningLotsApi
   */
  public getMyProvisioningLot(options?: RawAxiosRequestConfig) {
    return ProvisioningLotsApiFp(this.configuration)
      .getMyProvisioningLot(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get an existing provisioning lot
   * @param {number} lotId
   * @param {string} hubId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningLotsApi
   */
  public getProvisioningLot(
    lotId: number,
    hubId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningLotsApiFp(this.configuration)
      .getProvisioningLot(lotId, hubId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get an existing paginated provisioning lots
   * @param {string} hubId
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {Array<GetProvisioningLotsOrderByEnum>} [orderBy]
   * @param {DateFilterDto} [createdAt]
   * @param {string} [search]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningLotsApi
   */
  public getProvisioningLots(
    hubId: string,
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    orderBy?: Array<GetProvisioningLotsOrderByEnum>,
    createdAt?: DateFilterDto,
    search?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningLotsApiFp(this.configuration)
      .getProvisioningLots(
        hubId,
        skip,
        take,
        includeTotalCount,
        orderBy,
        createdAt,
        search,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Partial update an existing provisioning lot
   * @param {number} lotId
   * @param {string} hubId
   * @param {ProvisioningLotPatchInputDto} provisioningLotPatchInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningLotsApi
   */
  public updateProvisioningLot(
    lotId: number,
    hubId: string,
    provisioningLotPatchInputDto: ProvisioningLotPatchInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningLotsApiFp(this.configuration)
      .updateProvisioningLot(
        lotId,
        hubId,
        provisioningLotPatchInputDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Issue new provisioning token.
   * @param {number} lotId
   * @param {string} hubId
   * @param {ProvisioningLotTokenUpdateInputDto} provisioningLotTokenUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningLotsApi
   */
  public updateProvisioningToken(
    lotId: number,
    hubId: string,
    provisioningLotTokenUpdateInputDto: ProvisioningLotTokenUpdateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ProvisioningLotsApiFp(this.configuration)
      .updateProvisioningToken(
        lotId,
        hubId,
        provisioningLotTokenUpdateInputDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetProvisioningLotsOrderByEnum = {
  CreatedAtAsc: 'createdAt[asc]',
  CreatedAtDesc: 'createdAt[desc]',
  NameAsc: 'name[asc]',
  NameDesc: 'name[desc]',
} as const;
export type GetProvisioningLotsOrderByEnum =
  (typeof GetProvisioningLotsOrderByEnum)[keyof typeof GetProvisioningLotsOrderByEnum];

/**
 * TwinsApi - axios parameter creator
 * @export
 */
export const TwinsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwin: async (
      deviceId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('getTwin', 'deviceId', deviceId);
      const localVarPath = `/api/devices/{deviceId}/twin`.replace(
        `{${'deviceId'}}`,
        encodeURIComponent(String(deviceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwinDelta: async (
      deviceId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('getTwinDelta', 'deviceId', deviceId);
      const localVarPath = `/api/devices/{deviceId}/twin/delta`.replace(
        `{${'deviceId'}}`,
        encodeURIComponent(String(deviceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetTwinsOrderByEnum>} [orderBy]
     * @param {GetTwinsStatusEnum} [status]
     * @param {Array<string>} [tags]
     * @param {Array<string>} [devicesIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwins: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetTwinsOrderByEnum>,
      status?: GetTwinsStatusEnum,
      tags?: Array<string>,
      devicesIds?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/devices/twins/_/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (orderBy) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (tags) {
        localVarQueryParameter['tags'] = tags;
      }

      if (devicesIds) {
        localVarQueryParameter['devicesIds'] = devicesIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetTwinsDeltaOrderByEnum>} [orderBy]
     * @param {GetTwinsDeltaStatusEnum} [status]
     * @param {Array<string>} [tags]
     * @param {Array<string>} [devicesIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwinsDelta: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetTwinsDeltaOrderByEnum>,
      status?: GetTwinsDeltaStatusEnum,
      tags?: Array<string>,
      devicesIds?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/devices/twins/delta/_/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (orderBy) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (tags) {
        localVarQueryParameter['tags'] = tags;
      }

      if (devicesIds) {
        localVarQueryParameter['devicesIds'] = devicesIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {TwinsPageQueryPostInputDto} twinsPageQueryPostInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGetTwins: async (
      twinsPageQueryPostInputDto: TwinsPageQueryPostInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'twinsPageQueryPostInputDto' is not null or undefined
      assertParamExists(
        'postGetTwins',
        'twinsPageQueryPostInputDto',
        twinsPageQueryPostInputDto,
      );
      const localVarPath = `/api/devices/twins/_/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        twinsPageQueryPostInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {TwinsPageQueryPostInputDto} twinsPageQueryPostInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGetTwinsDelta: async (
      twinsPageQueryPostInputDto: TwinsPageQueryPostInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'twinsPageQueryPostInputDto' is not null or undefined
      assertParamExists(
        'postGetTwinsDelta',
        'twinsPageQueryPostInputDto',
        twinsPageQueryPostInputDto,
      );
      const localVarPath = `/api/devices/twins/delta/_/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        twinsPageQueryPostInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {TwinUpdateInputDto} twinUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTwinDesired: async (
      deviceId: string,
      twinUpdateInputDto: TwinUpdateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('updateTwinDesired', 'deviceId', deviceId);
      // verify required parameter 'twinUpdateInputDto' is not null or undefined
      assertParamExists(
        'updateTwinDesired',
        'twinUpdateInputDto',
        twinUpdateInputDto,
      );
      const localVarPath = `/api/devices/{deviceId}/twin/desired`.replace(
        `{${'deviceId'}}`,
        encodeURIComponent(String(deviceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        twinUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Performs an update on multiple devices twins desired attributes according to query params filter.<br>Query params filter attributes considered as operands of logical AND expression.<br>Wherein: <br><ul><li>If defined, elements of <code>devicesIds</code> array redcue to logical <code>OR</code> expression</li> and can be used to narrow search result to a list of specified IDs;\'<li>If defined, elements of <code>devicesTags</code> array redcue to logical <code>hasAny</code> expression</li> and can be used to narrow search result to a devices matching specified tags;<li>If defined, <code>deviceStatus</code> and can be used to narrow search result to a devices matching specified status.</ul><br>For example: <code>devicesIds=dev-1&devicesIds=dev-2&devicesTags=t-1&devicesTags=t-2&deviceStatus=ACTIVE</code> means:<br>all devices, where:<br><ul><li>device id is <code>\"dev-1\" OR \"dev-2\"</code></li><li><code>AND</code> device tags has any of <code>\"t-1\" OR \"t-2\"</code></li><li><code>AND</code> deviceStatus is <code>\"ACTIVE\"</code></li></ul>
     * @summary
     * @param {TwinsUpdateInputDto} twinsUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTwinsDesired: async (
      twinsUpdateInputDto: TwinsUpdateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'twinsUpdateInputDto' is not null or undefined
      assertParamExists(
        'updateTwinsDesired',
        'twinsUpdateInputDto',
        twinsUpdateInputDto,
      );
      const localVarPath = `/api/devices/twins/desired`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HubApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        twinsUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TwinsApi - functional programming interface
 * @export
 */
export const TwinsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TwinsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTwin(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwinDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTwin(
        deviceId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TwinsApi.getTwin']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTwinDelta(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwinDeltaDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTwinDelta(
        deviceId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TwinsApi.getTwinDelta']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetTwinsOrderByEnum>} [orderBy]
     * @param {GetTwinsStatusEnum} [status]
     * @param {Array<string>} [tags]
     * @param {Array<string>} [devicesIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTwins(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetTwinsOrderByEnum>,
      status?: GetTwinsStatusEnum,
      tags?: Array<string>,
      devicesIds?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwinsPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTwins(
        skip,
        take,
        includeTotalCount,
        orderBy,
        status,
        tags,
        devicesIds,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TwinsApi.getTwins']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetTwinsDeltaOrderByEnum>} [orderBy]
     * @param {GetTwinsDeltaStatusEnum} [status]
     * @param {Array<string>} [tags]
     * @param {Array<string>} [devicesIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTwinsDelta(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetTwinsDeltaOrderByEnum>,
      status?: GetTwinsDeltaStatusEnum,
      tags?: Array<string>,
      devicesIds?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TwinsDeltaPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTwinsDelta(
        skip,
        take,
        includeTotalCount,
        orderBy,
        status,
        tags,
        devicesIds,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TwinsApi.getTwinsDelta']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {TwinsPageQueryPostInputDto} twinsPageQueryPostInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGetTwins(
      twinsPageQueryPostInputDto: TwinsPageQueryPostInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwinsPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postGetTwins(
        twinsPageQueryPostInputDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TwinsApi.postGetTwins']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {TwinsPageQueryPostInputDto} twinsPageQueryPostInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGetTwinsDelta(
      twinsPageQueryPostInputDto: TwinsPageQueryPostInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TwinsDeltaPageDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postGetTwinsDelta(
          twinsPageQueryPostInputDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TwinsApi.postGetTwinsDelta']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {TwinUpdateInputDto} twinUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTwinDesired(
      deviceId: string,
      twinUpdateInputDto: TwinUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwinDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateTwinDesired(
          deviceId,
          twinUpdateInputDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TwinsApi.updateTwinDesired']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Performs an update on multiple devices twins desired attributes according to query params filter.<br>Query params filter attributes considered as operands of logical AND expression.<br>Wherein: <br><ul><li>If defined, elements of <code>devicesIds</code> array redcue to logical <code>OR</code> expression</li> and can be used to narrow search result to a list of specified IDs;\'<li>If defined, elements of <code>devicesTags</code> array redcue to logical <code>hasAny</code> expression</li> and can be used to narrow search result to a devices matching specified tags;<li>If defined, <code>deviceStatus</code> and can be used to narrow search result to a devices matching specified status.</ul><br>For example: <code>devicesIds=dev-1&devicesIds=dev-2&devicesTags=t-1&devicesTags=t-2&deviceStatus=ACTIVE</code> means:<br>all devices, where:<br><ul><li>device id is <code>\"dev-1\" OR \"dev-2\"</code></li><li><code>AND</code> device tags has any of <code>\"t-1\" OR \"t-2\"</code></li><li><code>AND</code> deviceStatus is <code>\"ACTIVE\"</code></li></ul>
     * @summary
     * @param {TwinsUpdateInputDto} twinsUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTwinsDesired(
      twinsUpdateInputDto: TwinsUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TwinsUpdateResultDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateTwinsDesired(
          twinsUpdateInputDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TwinsApi.updateTwinsDesired']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TwinsApi - factory interface
 * @export
 */
export const TwinsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TwinsApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwin(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TwinDto> {
      return localVarFp
        .getTwin(deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwinDelta(
      deviceId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TwinDeltaDto> {
      return localVarFp
        .getTwinDelta(deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetTwinsOrderByEnum>} [orderBy]
     * @param {GetTwinsStatusEnum} [status]
     * @param {Array<string>} [tags]
     * @param {Array<string>} [devicesIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwins(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetTwinsOrderByEnum>,
      status?: GetTwinsStatusEnum,
      tags?: Array<string>,
      devicesIds?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TwinsPageDto> {
      return localVarFp
        .getTwins(
          skip,
          take,
          includeTotalCount,
          orderBy,
          status,
          tags,
          devicesIds,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {Array<GetTwinsDeltaOrderByEnum>} [orderBy]
     * @param {GetTwinsDeltaStatusEnum} [status]
     * @param {Array<string>} [tags]
     * @param {Array<string>} [devicesIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTwinsDelta(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      orderBy?: Array<GetTwinsDeltaOrderByEnum>,
      status?: GetTwinsDeltaStatusEnum,
      tags?: Array<string>,
      devicesIds?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TwinsDeltaPageDto> {
      return localVarFp
        .getTwinsDelta(
          skip,
          take,
          includeTotalCount,
          orderBy,
          status,
          tags,
          devicesIds,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {TwinsPageQueryPostInputDto} twinsPageQueryPostInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGetTwins(
      twinsPageQueryPostInputDto: TwinsPageQueryPostInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TwinsPageDto> {
      return localVarFp
        .postGetTwins(twinsPageQueryPostInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {TwinsPageQueryPostInputDto} twinsPageQueryPostInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGetTwinsDelta(
      twinsPageQueryPostInputDto: TwinsPageQueryPostInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TwinsDeltaPageDto> {
      return localVarFp
        .postGetTwinsDelta(twinsPageQueryPostInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} deviceId
     * @param {TwinUpdateInputDto} twinUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTwinDesired(
      deviceId: string,
      twinUpdateInputDto: TwinUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TwinDto> {
      return localVarFp
        .updateTwinDesired(deviceId, twinUpdateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Performs an update on multiple devices twins desired attributes according to query params filter.<br>Query params filter attributes considered as operands of logical AND expression.<br>Wherein: <br><ul><li>If defined, elements of <code>devicesIds</code> array redcue to logical <code>OR</code> expression</li> and can be used to narrow search result to a list of specified IDs;\'<li>If defined, elements of <code>devicesTags</code> array redcue to logical <code>hasAny</code> expression</li> and can be used to narrow search result to a devices matching specified tags;<li>If defined, <code>deviceStatus</code> and can be used to narrow search result to a devices matching specified status.</ul><br>For example: <code>devicesIds=dev-1&devicesIds=dev-2&devicesTags=t-1&devicesTags=t-2&deviceStatus=ACTIVE</code> means:<br>all devices, where:<br><ul><li>device id is <code>\"dev-1\" OR \"dev-2\"</code></li><li><code>AND</code> device tags has any of <code>\"t-1\" OR \"t-2\"</code></li><li><code>AND</code> deviceStatus is <code>\"ACTIVE\"</code></li></ul>
     * @summary
     * @param {TwinsUpdateInputDto} twinsUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTwinsDesired(
      twinsUpdateInputDto: TwinsUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TwinsUpdateResultDto> {
      return localVarFp
        .updateTwinsDesired(twinsUpdateInputDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TwinsApi - object-oriented interface
 * @export
 * @class TwinsApi
 * @extends {BaseAPI}
 */
export class TwinsApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TwinsApi
   */
  public getTwin(deviceId: string, options?: RawAxiosRequestConfig) {
    return TwinsApiFp(this.configuration)
      .getTwin(deviceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TwinsApi
   */
  public getTwinDelta(deviceId: string, options?: RawAxiosRequestConfig) {
    return TwinsApiFp(this.configuration)
      .getTwinDelta(deviceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {Array<GetTwinsOrderByEnum>} [orderBy]
   * @param {GetTwinsStatusEnum} [status]
   * @param {Array<string>} [tags]
   * @param {Array<string>} [devicesIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TwinsApi
   */
  public getTwins(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    orderBy?: Array<GetTwinsOrderByEnum>,
    status?: GetTwinsStatusEnum,
    tags?: Array<string>,
    devicesIds?: Array<string>,
    options?: RawAxiosRequestConfig,
  ) {
    return TwinsApiFp(this.configuration)
      .getTwins(
        skip,
        take,
        includeTotalCount,
        orderBy,
        status,
        tags,
        devicesIds,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {Array<GetTwinsDeltaOrderByEnum>} [orderBy]
   * @param {GetTwinsDeltaStatusEnum} [status]
   * @param {Array<string>} [tags]
   * @param {Array<string>} [devicesIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TwinsApi
   */
  public getTwinsDelta(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    orderBy?: Array<GetTwinsDeltaOrderByEnum>,
    status?: GetTwinsDeltaStatusEnum,
    tags?: Array<string>,
    devicesIds?: Array<string>,
    options?: RawAxiosRequestConfig,
  ) {
    return TwinsApiFp(this.configuration)
      .getTwinsDelta(
        skip,
        take,
        includeTotalCount,
        orderBy,
        status,
        tags,
        devicesIds,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {TwinsPageQueryPostInputDto} twinsPageQueryPostInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TwinsApi
   */
  public postGetTwins(
    twinsPageQueryPostInputDto: TwinsPageQueryPostInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return TwinsApiFp(this.configuration)
      .postGetTwins(twinsPageQueryPostInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {TwinsPageQueryPostInputDto} twinsPageQueryPostInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TwinsApi
   */
  public postGetTwinsDelta(
    twinsPageQueryPostInputDto: TwinsPageQueryPostInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return TwinsApiFp(this.configuration)
      .postGetTwinsDelta(twinsPageQueryPostInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} deviceId
   * @param {TwinUpdateInputDto} twinUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TwinsApi
   */
  public updateTwinDesired(
    deviceId: string,
    twinUpdateInputDto: TwinUpdateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return TwinsApiFp(this.configuration)
      .updateTwinDesired(deviceId, twinUpdateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Performs an update on multiple devices twins desired attributes according to query params filter.<br>Query params filter attributes considered as operands of logical AND expression.<br>Wherein: <br><ul><li>If defined, elements of <code>devicesIds</code> array redcue to logical <code>OR</code> expression</li> and can be used to narrow search result to a list of specified IDs;\'<li>If defined, elements of <code>devicesTags</code> array redcue to logical <code>hasAny</code> expression</li> and can be used to narrow search result to a devices matching specified tags;<li>If defined, <code>deviceStatus</code> and can be used to narrow search result to a devices matching specified status.</ul><br>For example: <code>devicesIds=dev-1&devicesIds=dev-2&devicesTags=t-1&devicesTags=t-2&deviceStatus=ACTIVE</code> means:<br>all devices, where:<br><ul><li>device id is <code>\"dev-1\" OR \"dev-2\"</code></li><li><code>AND</code> device tags has any of <code>\"t-1\" OR \"t-2\"</code></li><li><code>AND</code> deviceStatus is <code>\"ACTIVE\"</code></li></ul>
   * @summary
   * @param {TwinsUpdateInputDto} twinsUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TwinsApi
   */
  public updateTwinsDesired(
    twinsUpdateInputDto: TwinsUpdateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return TwinsApiFp(this.configuration)
      .updateTwinsDesired(twinsUpdateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetTwinsOrderByEnum = {
  CreatedAtAsc: 'createdAt[asc]',
  CreatedAtDesc: 'createdAt[desc]',
  StatusAsc: 'status[asc]',
  StatusDesc: 'status[desc]',
} as const;
export type GetTwinsOrderByEnum =
  (typeof GetTwinsOrderByEnum)[keyof typeof GetTwinsOrderByEnum];
/**
 * @export
 */
export const GetTwinsStatusEnum = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
} as const;
export type GetTwinsStatusEnum =
  (typeof GetTwinsStatusEnum)[keyof typeof GetTwinsStatusEnum];
/**
 * @export
 */
export const GetTwinsDeltaOrderByEnum = {
  CreatedAtAsc: 'createdAt[asc]',
  CreatedAtDesc: 'createdAt[desc]',
  StatusAsc: 'status[asc]',
  StatusDesc: 'status[desc]',
} as const;
export type GetTwinsDeltaOrderByEnum =
  (typeof GetTwinsDeltaOrderByEnum)[keyof typeof GetTwinsDeltaOrderByEnum];
/**
 * @export
 */
export const GetTwinsDeltaStatusEnum = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
} as const;
export type GetTwinsDeltaStatusEnum =
  (typeof GetTwinsDeltaStatusEnum)[keyof typeof GetTwinsDeltaStatusEnum];
